/**
 * Important: Because we now have the MaterialModule inside of the shared module file, 
 * we don’t need them anymore in the app and other module files. Therefore, we can remove the 
 * MaterialModule imports from the app and other module files and just import the SharedModule 
 * in both mentioned module files (app and other).
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/** MaterialModule */
import { MaterialModule } from '../material';
/** Custom pipes */
import { ArraySortPipe, ValueCheckInArrayPipe } from "../_custom-pipes";
/** components */
import { SuccessDialogComponent } from './dialogs/success-dialog';
import { ErrorDialogComponent } from './dialogs/error-dialog';
import { MessageSnackbarComponent } from './snackbars/message-snackbar/message-snackbar.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { SpinnerComponent } from './spinner/spinner/spinner.component';
import { AmchartsComponent } from './charts/amcharts/amcharts.component';
/** components */
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';

/** calender relative files*/
// import { CalenderComponent } from "../calender/calender.component";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ThemeDirective } from './directives/theme.directive';
/** calender relative files*/

/** Dynamic component loading module */
import { DynamicModule } from 'ng-dynamic-component';
/** Dynamic component loading module */

/** Gridster loading module */
import { GridsterModule } from 'angular-gridster2';
/** Gridster loading module */

import { DynamicFormModule } from "./dynamic-form-component";

import { NgImageSliderModule } from 'ng-image-slider';

import { PdfComponent } from "../pdf/pdf.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DynamicModule.withComponents([]),
    GridsterModule,
    DynamicFormModule,
    NgImageSliderModule,
  ],

  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ArraySortPipe,
    ValueCheckInArrayPipe,
    SuccessDialogComponent,
    ErrorDialogComponent,
    MessageSnackbarComponent,
    SpeedDialFabComponent,
    SpinnerComponent,

    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AmchartsComponent,
    PdfComponent,

    GridsterModule,
    DynamicModule,
    NgImageSliderModule,
  ],

  declarations: [
    ArraySortPipe,
    ValueCheckInArrayPipe,
    SuccessDialogComponent,
    ErrorDialogComponent,
    MessageSnackbarComponent,
    SpeedDialFabComponent,
    SpinnerComponent,

    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ThemeDirective,
    AmchartsComponent,
    PdfComponent
  ],

  entryComponents: [
    SuccessDialogComponent,
    ErrorDialogComponent,
    MessageSnackbarComponent,
    SpeedDialFabComponent,
    SpinnerComponent,
    AmchartsComponent,
    PdfComponent
  ]

})
export class SharedModule { }
