import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/** ReactiveFormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/** MaterialModule */
import { FormMaterialModule } from '../_form-material';

/**All Components */
import { InputComponent } from "../input";
import { CheckboxComponent } from "../checkbox";
import { CheckboxGroupComponent } from "../checkbox-group";
import { DateComponent } from "../date";
import { FileComponent } from "../file";
import { ListComponent } from "../list";
import { NoteComponent } from "../note";
import { RadiobuttonComponent } from "../radiobutton";
import { SelectComponent } from "../select";
import { TextareaComponent } from "../textarea";
import { ButtonComponent } from "../button";
/**All Components */


/**All Components */
import { DynamicFieldDirective, DisableControlDirective } from "../dynamic-field";
import { DynamicFormComponent } from "./dynamic-form.component";
/**All Components */

@NgModule({
  declarations: [
    InputComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DateComponent,
    FileComponent,
    ListComponent,
    NoteComponent,
    RadiobuttonComponent,
    TextareaComponent,
    SelectComponent,
    ButtonComponent,

    DynamicFieldDirective,
    DynamicFormComponent,
    DisableControlDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormMaterialModule,
  ],
  exports: [
    InputComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DateComponent,
    FileComponent,
    ListComponent,
    NoteComponent,
    RadiobuttonComponent,
    ButtonComponent,
    TextareaComponent,
    SelectComponent,

    DynamicFieldDirective,
    DynamicFormComponent,
    DisableControlDirective
  ],
  entryComponents: [
    InputComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DateComponent,
    FileComponent,
    ListComponent,
    NoteComponent,
    RadiobuttonComponent,
    ButtonComponent,
    TextareaComponent,
    SelectComponent,

    DynamicFormComponent,
  ],
})
export class DynamicFormModule { }
