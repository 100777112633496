import { Component, HostBinding, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormControl } from "@angular/forms";


import { Router, ActivatedRoute } from "@angular/router";
import { FormDataService } from '../services/form-data.service';
import { FieldConfig } from "../interface/field.interface";
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
 /** only particular field data */
 field: FieldConfig;
 /** only particular field group data */
 group: FormGroup;

 onChange: Function;

 /** Total dynamic form "this" data */
 dynamicFormCompRef: any;

 /** total form data values */
 formData: FormData;
 /** only particular field value */
 fieldData: object;
 div_Partition_value: string;
 
 /** Div partition default value set here */
 divPartitionColumnsDefault: string = this.formDataService.getDivPartitionColumnsDefault();


 /**
  * Adding Class & Id to selector tag here
  * Using HostBinding directive
  */
 /**
  * Reference urls list below 
  * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
  * https://github.com/angular/angular/issues/7289#issuecomment-390415535
  * https://alligator.io/angular/hostbinding-hostlistener/
  */
 /** Class set here */
 @HostBinding('class')
 get hostClasses(): string {
   return [
     'form-group',
     this.field.divPartitionColumns ? this.div_Partition_value + this.field.divPartitionColumns : this.divPartitionColumnsDefault,
     "sector-cls-" + this.field.name,
     (!this.field.hidden) ? 'fieldShow' : 'fieldHidden',
   ].join(' ');
 }
 /** Id set here */
 @HostBinding('attr.id')
 get idSet(): string {
   return "sector-id-" + this.field.name;
 }
 /************************* End Here ***************************/


 /**
  * Get Current url path here
  */
 public currentUrl = this.router.url;
 public currentUrlName = this.currentUrl.replace('/', '');

 public outputDataObject: any = {};

 /**
  * 
  * @param router 
  * @param formDataService 
  */
 constructor(private router: Router, private formDataService: FormDataService) {
   let outputData = this.formDataService.getOutputData(null);
   if (outputData[this.currentUrlName] != '') {
     this.outputDataObject = outputData[this.currentUrlName];
   }
   this.div_Partition_value = formDataService.getDivPartitionColumns();
 }

 ngOnInit() {
   // console.log(this.formData);
   // console.log(this.fieldData);
 }

 /**
  * On change value bind to checkbox
  * @param inputValue 
  * @param isChecked 
  * @param fieldName 
  */
 onValueChanged(inputValue: string, isChecked: boolean, fieldName: any) {
   console.log(inputValue);
   const formControl = <FormControl>fieldName
   if (isChecked) {
     formControl.setValue(true);
   } else {
     formControl.setValue(false);
   }
   /**Another function call here */
   this.onChange && this.onChange.call(this.dynamicFormCompRef, this.field);
 }


 /**
  * Lets' Convert 1 '1' and 'true' to true and 0 '0' 'false' null and undefined to false
  * url: https://bloomlab.blogspot.com/2018/06/typescript-recipe-elegant-parse-boolean.html 
  * @param value 
  */
 public primitiveToBoolean(value?: string | number | boolean | null): boolean {
   if (value == null) {
     return false;
   }
   if (value === 'true') {
     return true;
   }
   return typeof value === 'string'
     ? !!+value   // we parse string to number first
     : !!value;
 }
}
