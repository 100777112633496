export class Student {
    _id:string;
    Id: number;
    AdmissionNumber: string;
    Section: string;
    Name: string;
    RollNo: string;
    Gender: string;
    DateOfBirth: string;
    Standard: string;
    ClassId:number;
    AcademicYear:string;
    ParentName:string;
    PrimaryContact:string;
    SecondaryContact:string;

    IsTransportOpted?:boolean;
    IsMessOpted?:boolean;
    IdentificationId?:string;
    PickPoint?:string;
    DropPoint?:string;
    PickArea?:string;
    DropArea?:string;
    mode?:any;
    routes?:any;
} 