// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // URL of development API
  apiUrl: 'https://a2stats.itraxpro.com/api/v1.0',

  // URL of LOCAL API
  //  apiUrl: 'http://localhost:3500/api/v1.0',

  // URL of Test API
  //  apiUrl: 'https://itraxpro.com/api/v1.0',

  // browser storage maintain either 'localStorage' or 'sessionStorage'
  browserStorage: 'sessionStorage'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
