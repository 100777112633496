import { Component, HostBinding, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormDataService } from '../services/form-data.service';
import { FieldConfig } from "../interface/field.interface";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent implements OnInit {

 /** only particular field data */
 field: FieldConfig;
 /** only particular field group data */
 group: FormGroup;

 onChange: Function;

 /** Total dynamic form "this" data */
 dynamicFormCompRef: any;

 /** total form data values */
 formData: object;
 /** only particular field value */

 fieldData: any;

 div_Partition_value:string;  

 /** Div partition default value set here */
 divPartitionColumnsDefault: string = this.formDataService.getDivPartitionColumnsDefault();


 /**
  * Adding Class & Id to selector tag here
  * Using HostBinding directive
  */
 /**
  * Reference urls list below 
  * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
  * https://github.com/angular/angular/issues/7289#issuecomment-390415535
  * https://alligator.io/angular/hostbinding-hostlistener/
  */
 /** Class set here */
 @HostBinding('class')
 get hostClasses(): string {
    return [
       'form-group',
       this.field.divPartitionColumns ? this.div_Partition_value + this.field.divPartitionColumns : this.divPartitionColumnsDefault,
       "sector-cls-" + this.field.name,
       (!this.field.hidden) ? 'fieldShow' : 'fieldHidden',
    ].join(' ');
 }

 /** Id set here */
 @HostBinding('attr.id')
 get idSet(): string {
    return "sector-id-" + this.field.name;
 }
 /************************* End Here ***************************/

 /**
  * Get Current url path here
  */
 public currentUrl = this.router.url;
 public currentUrlName = this.currentUrl.replace('/', '');

 public radioOutputDataObject: any = {};

 /**
  * 
  * @param router 
  * @param formDataService 
  */
 constructor(private router: Router, private formDataService: FormDataService) {
    let radioutputData = this.formDataService.getOutputData(null);
    if (radioutputData[this.currentUrlName] != '') {
       this.radioOutputDataObject = radioutputData[this.currentUrlName];
    }
    this.div_Partition_value = formDataService.getDivPartitionColumns();
 }

 ngOnInit() { }


 excuteFormCondition() {
    this.onChange && this.onChange.call(this.dynamicFormCompRef, this.field);
 }


}
