import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { DynamicFormModule } from "./dynamic-form";


	   
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    // DynamicFormModule
  ],
  exports:[
  ],
  entryComponents: [
  ],
})
export class DynamicFormComponentModule { }
