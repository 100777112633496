import { Component, HostBinding, HostListener, ViewChild, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { FormDataService } from '../services/form-data.service';
import { FieldConfig } from "../interface/field.interface";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  /** only particular field data */
  field: FieldConfig;
  /** only particular field group data */
  group: FormGroup;

  onChange: Function;

  /** Total dynamic form "this" data */
  dynamicFormCompRef: any;

  /** total form data values */
  formData: object;
  /** only particular field value */
  fieldData: any;
  div_Partition_value: string;

  /** Div partition default value set here */
  divPartitionColumnsDefault: string = this.formDataService.getDivPartitionColumnsDefault();

  /**
   * Adding Class & Id to selector tag here
   * Using HostBinding directive
   */
  /**
   * Reference urls list below 
   * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
   * https://github.com/angular/angular/issues/7289#issuecomment-390415535
   * https://alligator.io/angular/hostbinding-hostlistener/
   */
  /** Class set here */
  @HostBinding('class')
  get hostClasses(): string {
    return [
      'form-group',
      this.field.divPartitionColumns ? this.div_Partition_value + this.field.divPartitionColumns : this.divPartitionColumnsDefault,
      "sector-cls-" + this.field.name,
      (!this.field.hidden) ? 'fieldShow' : 'fieldHidden',
    ].join(' ');
  }

  /** Id set here */
  @HostBinding('attr.id')
  get idSet(): string {
    return "sector-id-" + this.field.name;
  }
  /************************* End Here ***************************/


  /**
   * Ngx Datepicker related code here
   */


  /**
   * Autoload function
   */
  /**
    * Ngx Datepicker:
    * URL: https://valor-software.com/ngx-bootstrap/#/datepicker
    * Datepicker comes with some default color schemes. 
    * You can change it by manipulating containerClass property in bsConfig object. 
    * There are 6 color schemes: 
    * theme-default, theme-green, theme-blue, theme-dark-blue, theme-red, theme-orange
    * 
    * You can easily change the date format by specifying the dateInputFormat in "bsConfig"
    * The following examples show how to use several date formats inside a form: YYYY-MM-DD, MM/DD/YYYY, MMMM Do YYYY,h:mm:ss a
    * dateInputFormat: 'DD-MM-YYYY' 
    */

  bsValue = new Date();
  minDate: Date;
  maxDate: Date;
  data: Date;
  constructor(public datepipe: DatePipe, private formDataService: FormDataService) {
    this.minDate = new Date(1990, 1, 1);
    //  this.maxDate = new Date(2016, 12, 31);
    this.maxDate = new Date();

    this.div_Partition_value = formDataService.getDivPartitionColumns();
  }

  ngOnInit() {
    this.fieldData = this.datepipe.transform(this.fieldData, 'dd-MM-yyyy');
  }

  /**
   * Ngx Datepicker: Hide the datepicker on page scroll.
   */
  @HostListener('window:scroll')
  onScrollEvent() {
    // this.datepicker.hide();
  }

  /**
   * Show & hide field function
   */
  excuteFormCondition() {
    this.onChange && this.onChange.call(this.dynamicFormCompRef, this.field);
  }



  /****************************************************************************************************
   * 
   * Future reference backup code
   * 
   ****************************************************************************************************/

  onValueChange(value: Date): void {
    var self = this;
    self.data = value;
    // this.dateFormat(this.fieldData)    
  }

  dateFormat(date: any) {
    var dates = new Date(date);
    var month = ("0" + (dates.getMonth() + 1)).slice(-2);
    var day = ("0" + dates.getDate()).slice(-2);
    let formattedDate = [day, month, dates.getFullYear()].join("-");
    // this.group.controls[this.field.name].patchValue(formattedDate);
    //this.group.patchValue({ dob: formattedDate });
    return formattedDate;
  }

  myFunction(value) {
    var self = this;
    let latest_date = self.datepipe.transform(value, 'yyyy-MM-dd');
    const formControl = <FormControl>this.group.get(this.field.name);
    formControl.setValue(latest_date);
  }


}
