import { Component, HostBinding, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../interface/field.interface";
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;


  /**
   * Adding Class & Id to selector tag here
   * Using HostBinding directive
   */
  /**
   * Reference urls list below 
   * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
   * https://github.com/angular/angular/issues/7289#issuecomment-390415535
   * https://alligator.io/angular/hostbinding-hostlistener/
   */
  /** Class set here */
  class: string = ''; // override the standard class attr with a new one.
  @HostBinding('class')
  get hostClasses(): string {
      return [
          // this.class, // include our old one 
          this.field.divPartitionColumns ? 'col-' + this.field.divPartitionColumns : 'col-12', // include our new one 
          "sector-cls-" + this.field.name,
      ].join(' ');
  }

  /** Id set here */
  @HostBinding('attr.id')
  get idSet(): string {
      return "sector-id-" + this.field.name;
  }
  /************************* End Here ***************************/

  constructor() { }

  ngOnInit() { }


}
