import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
/** HttpInterceptor */
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
/** MaterialModule */
/** MaterialModule */
// import { MaterialModule } from './material';
/** HttpConfigInterceptor */
import { HttpConfigInterceptor } from './_interceptor';

/** App */
import { AppRoutingModule } from './app-routing.module';
import { RoutingModule } from './routing';
import { AppComponent } from './app.component';

/** Shared Module loding here */
import { SharedModule } from './shared/shared.module';

/** other components before login folders */
import { LoginComponent } from './login';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { ServerErrorComponent } from './error-pages/server-error/server-error.component';
import { GroupByPipe } from './_custom-pipes/group-by.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    ServerErrorComponent,
    GroupByPipe,
  ],

  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RoutingModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
