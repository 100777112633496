import { Component } from '@angular/core';
import { CommonService } from './_services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Asquare';

  constructor(private commonService: CommonService,private http: HttpClient) {
    // this.commonService.activeThemeCssClass = 'teal-indigo-pink';
    this.commonService.activeThemeCssClass = 'pink-teal-pink';

    this.commonService.setTheme(this.commonService.activeThemeCssClass, false); // Default Theme
    // this.commonService.setTheme('indigo-pink', false); // Theme 1
    // this.commonService.setTheme('purple-green', false); // Theme  2    
    // this.commonService.setTheme('deeppurple-amber', false); // Theme  3   
    // console.log(this.commonService.activeThemeCssClass);

  }


}