import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    /**
     * 
     * @param apiService 
     */
    constructor(private router: Router, private apiService: ApiService, private sessionService: SessionService) { }


    /**
     * @param requestParams
     */
    getLoggedInUser() {
        /**API Method Name */
        let apiMethod = 'getLoggedInUser';
        /** API - GET SERVICE REQUEST */
        return this.apiService.get(apiMethod);
    }

    /**
     * @param requestParams
     */
    login(requestParams: object) {
        /**API Method Name */
        let apiMethod = 'login';
        /**API Body Data */
        let apiBody = requestParams;
        /** API - POST SERVICE REQUEST */
        return this.apiService.post(apiMethod, apiBody);
    }

    /**
     * @param requestParams
     */
    logoutAPI(requestParams: object) {
        /**API Method Name */
        let apiMethod = 'logout';
        /**API Body Data */
        let apiBody = requestParams;
        /** API - POST SERVICE REQUEST */
        return this.apiService.post(apiMethod, apiBody);
    }

    /**
     * Logout
     */
    logout() {
        /** API - POST REQUSET DATA */
        let requestData: object = {};
        var self = this;
        this.logoutAPI(requestData).subscribe((responseData) => {
            // console.log(responseData);
            if (responseData != null) {
                // remove user from browser storage to log user out
                this.sessionService.clear();
                self.router.navigate(["login"]);
            } else {
                console.log('Response data is null');
            }
        }, (error) => {
            // console.log(error.error);
        });
    }

    
     /**
     * @param requestParams
     */
    changepassword(requestParams: object) {
        /**API Method Name */
        let apiMethod = 'changePassword';
        /**API Body Data */
        let apiBody = requestParams;
        /** API - POST SERVICE REQUEST */
        return this.apiService.post(apiMethod, apiBody);
    }
}