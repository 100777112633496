import { Component, HostBinding, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FormDataService } from '../services/form-data.service';
import { FieldConfig } from "../interface/field.interface";
import { Router } from '@angular/router';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit {

  /** only particular field data */
  field: FieldConfig;
  /** only particular field group data */
  group: FormGroup;

  onChange: Function;

  /** Total dynamic form "this" data */
  dynamicFormCompRef: any;

  /** total form data values */
  formData: object;
  /** only particular field value */
  fieldData: any;
  div_Partition_value:string;
  
  /** Div partition default value set here */
  divPartitionColumnsDefault: string = this.formDataService.getDivPartitionColumnsDefault();


  /**
   * Adding Class & Id to selector tag here
   * Using HostBinding directive
   */
  /**
   * Reference urls list below 
   * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
   * https://github.com/angular/angular/issues/7289#issuecomment-390415535
   * https://alligator.io/angular/hostbinding-hostlistener/
   */
  /** Class set here */
  @HostBinding('class')
  get hostClasses(): string {
    return [
      'form-group',
      this.field.divPartitionColumns ? this.div_Partition_value + this.field.divPartitionColumns : this.divPartitionColumnsDefault,
      "sector-cls-" + this.field.name,
      (!this.field.hidden) ? 'fieldShow' : 'fieldHidden',
    ].join(' ');
  }

  /** Id set here */
  @HostBinding('attr.id')
  get idSet(): string {
    return "sector-id-" + this.field.name;
  }
  /************************* End Here ***************************/


  fileArr: any = [];

  form: FormGroup;
  loading: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;

  /**
   * Get Current url path here
   */
  public currentUrl = this.router.url;
  public currentUrlName = this.currentUrl.replace('/', '');

  public outputDataObject: any = {};

  public imgResulturl;
  public value;
  public filepathd;

  /**
   * 
   * @param fb 
   * @param formDataService 
   * @param router 
   */
  constructor(private fb: FormBuilder, private formDataService: FormDataService, private router: Router) {

    let outputData = this.formDataService.getOutputData(null);

    if (outputData[this.currentUrlName] != '') {
      this.outputDataObject = outputData[this.currentUrlName];
      for (let key in this.outputDataObject) {
        this.value = this.outputDataObject[key];
        if (this.value['filevalue']) {
          this.filepathd = this.value['filevalue'];
        }
      }
    }
    this.div_Partition_value = formDataService.getDivPartitionColumns();

  }

  ngOnInit() {
    // console.log(this.fieldData);
  }
  /**
   * Show & hide field function
   */
  excuteFormCondition() {
    this.onChange && this.onChange.call(this.dynamicFormCompRef, this.field);
  }

  /**
   * get file data here
   * @param event 
   * @param field_name 
   */
  onFileChange(event, field_name) {

    this.fileArr = {};

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        this.fileArr['filename'] = file.name;
        this.fileArr['filetype'] = file.type;
        this.fileArr['filesize'] = file.size;
        this.fileArr['filevalue'] = reader.result;
        this.imgResulturl = (<FileReader>event.target).result;
        this.group.get(field_name).setValue(this.fileArr);
      };
    }


  }

  /**
   * example for onsubmit for files
   */
  onSubmit() {
    const formModel = this.form.value;
    this.loading = true;
    // In a real-world app you'd have a http request / service call here like
    // this.http.post('apiUrl', formModel)
    setTimeout(() => {
      console.log(formModel);
      alert('done!');
      this.loading = false;
    }, 1000);
  }

  /**
   * Clear file data here
   */
  clearFile() {
    this.form.get('filedata').setValue(null);
    this.fileInput.nativeElement.value = '';
  }

}
