import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '../_services';
import { MessageSnackbarComponent } from "../shared/snackbars";
import { MatSnackBar } from '@angular/material';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// import { ErrorDialogService } from '../shared/error-dialog/errordialog.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private snackBar: MatSnackBar, private sessionService: SessionService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add authorization header with jwt token if available
        let currentUser = this.sessionService.getData();
        // console.log(currentuser);
        if (currentUser && currentUser.Token) {
            const token: string = currentUser.Token;
            /** 
             * Clone the request and replace the original headers with
             * cloned headers, updated with the token. 
            */
            request = request.clone({ headers: request.headers.set('token', token) });

            /** Clone the request and set the new header in one step. */
            //request = request.clone({ setHeaders: { token: token } });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        // request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });

        /** clone request and replace 'http://' with 'https://' at the same time */
        //request = request.clone({ url: request.url.replace('http://', 'https://') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                //console.log('<<<--- API CALL SUCCESS --->>>');
                //console.log(event);
                /*  if (request.method === 'GET' && event instanceof HttpResponse) {
                     // console.log("Response Interceptor for GET");
                     // return event.clone({ body: event.body['posts'] });
                 } else if (request.method === 'POST' && event instanceof HttpResponse) {
                     //console.log("Response Interceptor for POST");
                     //return event.clone({ body: event.body['posts'] });
                 } */
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status,
                    statusText: error.statusText
                };
                // console.log('<<<--- API CALL ERROR --->>>');
                // console.log(data);
                if (error.status == 401) {
                    // remove user from browser storage to log user out
                    this.sessionService.clear();
                    // Unauthorized Error so redirect to login page with the return url
                    this.router.navigate(["login"]);
                } else {
                    this.ToastMsg('Server Error: ' + error.status, 2);
                }
                return throwError(error);
            })
        );
    }


    /******************************************************************************************* 
      * SnackBar code
      * @param message 
      * @param durationInSeconds 
      */
    ToastMsg(message: string, durationInSeconds?: number) {
        let durationInSecondsVal = (durationInSeconds) ? durationInSeconds : 2;
        this.snackBar.openFromComponent(MessageSnackbarComponent, {
            data: message,
            duration: durationInSecondsVal * 1000,
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

}