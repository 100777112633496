import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Validators } from '@angular/forms';
import { FieldConfig } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class StudentService {


  unamePattern = "^[a-z0-9_-]{8,15}$";
  pwdPattern = "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,12}$";
  mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  namePattern = "^[a-zA-Z ]{1,20}$";
  genderPattern = "^[a-zA-Z]{4,6}$";
  addressPattern = "^[a-zA-Z0-9-. ]{1,100}$";
  phonePattern = "^[0-9-]{10,15}$";
  yearPattern = "^[0-9]{4,4}$";
  passportPattern = "^[0-9]{10,10}$";
  aadharPattern = "^[0-9]{12,12}$";
  bloodGroupPattern = "^[a-zA-z+]{2,10}$";

  textareaPattern = "^[A-za-z0-9\n \,\.\-]{1,100}$";
  inputPattern = "^[a-zA-Z ]{1,20}$";
  incomePattern = "^[0-9,.]{5,9}$";
  dobPattern = "^[0-9-]{10,10}$";

  /**
   * Form Field Config Variables start here
   */
  public formFieldConfig: FieldConfig[];

  standardsData = [];
  sectionsData = [];

  subscribedInPutValuesJson;
  standards;
  public classIdJson: any;

  /**
   * 
   * @param apiService 
   */
  constructor(private apiService: ApiService) {
    this.getStandardsApi();
  }



  getStudentPhotosStatus(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'getStudentPhotosStatus';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  getStudentsWithPhotosTaken(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'getStudentsWithPhotosTaken';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  getStudentsWithPhotosNotTaken(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'getStudentsWithPhotosNotTaken';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }


  /**
  * @param requestParams
  */
  getStandards(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'getStandards';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  /**
   * @param requestParams
   */
  getStudents(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'getStudents';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  /**
   * @param requestParams
   */
  deleteStudents(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'deleteStudents';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  /**
   * 
   * @param requestParams 
   */
  getStudentById(requestParams: object) {
    /**API Method Name */
    let apiMethod = 'getStudentById';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  /**
   * 
   * @param requestParams 
   */
  createStudents(requestParams: any) {
    /**API Method Name */
    let apiMethod = 'createStudents';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  /**
   * 
   * @param requestParams 
   */
  createStudent(requestParams: any) {
    /**API Method Name */
    let apiMethod = 'createStudent';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }

  /**
   * @param requestParams
   */
  updateStudent(requestParams: any) {
    /**API Method Name */
    let apiMethod = 'updateStudent';
    /**API Body Data */
    let apiBody = requestParams;
    /** API - POST SERVICE REQUEST */
    return this.apiService.post(apiMethod, apiBody);
  }


  /**
   * getStandardsApi
   */
  private getStandardsApi = () => {
    /** API - POST REQUSET DATA */
    let requestData: object = {};
    this.getStandards(requestData).subscribe((responseData) => {
      // console.log(responseData);
      if (responseData != null) {
        // console.log("getStandardsApi", responseData);
        this.getStandard(responseData);
        // this.getStandardSection(responseData);
      } else {
        console.log("No data found");
      }
    }, (error) => {
      console.log(error.error);
      // let msg = (error.error.Message) ? error.error.Message : error.error.message;
      // this.commonService.ToastMsg(msg);
    });
  }

  public getStandard(resData) {
    /** rename keys */
    var arrayObj = resData;
    /*  let sample = {
       "_id": "5af027638d2d9d6030446fa2",
       "Id": 396,
       "Name": "Grade 02",
       "Sections": [
         {
           "Name": "A"
         }
       ]
     }; */
    arrayObj = arrayObj.map(item => {
      return {
        key: item._id,
        label: item.Name,
        id: item.Id,
        Sections: item.Sections,
      };
    });
    this.standardsData = arrayObj;
    // console.log("getStandard---", arrayObj)
  }

  /**
   * 
   * @param item 
   */
  public getSections(item) {
    let resData = item.Sections;
    /** rename keys */
    var arrayObj = resData;
    arrayObj = arrayObj.map(item => {
      return {
        key: item.Name,
        label: item.Name,
      };
    });
    this.sectionsData = arrayObj;
    this.formFieldConfig.find(item => item.name == "Section").options = arrayObj;
    // console.log("getSections - regConfig", this.formFieldConfig);
  }


  /**
    * getFormFieldConfig
    */
  getFormFieldConfig() {

    var self = this;

    self.formFieldConfig = [

      {
        type: 'input',
        inputType: "text",
        label: ' Name',
        name: 'Name',
        value: '',
        isReadOnly: false,
        required: true,
        icon: true,
        icon_name: "perm_identity",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Name Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z \.]+$"),
            message: "Accept only Characters and space"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

      /** dropdown */
      {
        type: "select",
        inputType: "select",
        label: "Semester/Class",
        name: "ClassId",
        value: "",
        isReadOnly: false,
        options: self.standardsData,
        DropDownValueIsObject: true,
        onCallback: function (dataObj: any) {
          console.log('----config data-----', dataObj);
          let isObject = dataObj.isObject;
          let selectedValue = dataObj.selectedValue;
          console.log(selectedValue);
          self.getSections(selectedValue);
        },
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Standard Required"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: "select",
        inputType: "select",
        label: "Branch/Section",
        name: "Section",
        value: "",
        isReadOnly: false,
        options: [],
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Section Required"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Admission Number',
        name: 'AdmissionNumber',
        value: '',
        isReadOnly: false,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Admission Number Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z0-9\-\/\\]+$"),
            message: "Accept only Characters and Numbers"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Roll Number',
        name: 'RollNo',
        value: '',
        isReadOnly: false,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Roll Number Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z0-9\-\/]+$"),
            message: "Accept only Characters and Numbers"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: "select",
        inputType: "select",
        label: "Academic Year ",
        name: "AcademicYear",
        value: "",
        isReadOnly: false,
        icon: false,
        icon_name: "",
        options: [
          { key: '2020-2021', label: '2020-2021' },
          { key: '2019-2020', label: '2019-2020' }
        ],
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Academic Year Required"
          }
        ],
        required: true,
        divPartitionColumns: 4,
      },
      {
        type: "radiobutton",
        inputType: "radio",
        label: "Gender",
        inline_class_name: 'form-check-inline',
        name: "Gender",
        icon: false,
        icon_name: "",
        options: [
          { key: 'm', label: 'Male' },
          { key: 'f', label: 'Female' }
        ],
        value: "",
        isReadOnly: false,
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Gender Required"
          }
        ],
        divPartitionColumns: 6,
        grid_column: 1,
        grid_row: 1,
      },
      {
        type: "date",
        inputType: "date",
        label: "Date Of Birth",
        name: "DateOfBirth",
        placeholder: "Date Of Birth",
        value: "",
        isReadOnly: false,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Date of Birth Required"
          }
        ],
        divPartitionColumns: 6,
        grid_column: 1,
        grid_row: 1,
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Parent Name',
        name: 'ParentName',
        value: '',
        isReadOnly: false,
        icon: true,
        icon_name: "perm_identity",
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "parent Name Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z ]+$"),
            message: "Accept only Characters and space"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Primary Contact',
        name: 'PrimaryContact',
        value: '',
        isReadOnly: false,
        required: true,
        icon: true,
        icon_name: "phone",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Primary Contact Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[0-9]{1,10}$"),
            message: "Accept numbers only"
          },
          {
            name: "maxlength",
            length: 10,
            validator: Validators.maxLength(10),
            message: "Maximum length 10 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Secondary Contact',
        name: 'SecondaryContact',
        value: '',
        isReadOnly: false,
        icon: true,
        icon_name: "phone",
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Secondary Contact Required"
          },
          {
            name: "pattern",
            // validator: Validators.pattern("^[0-9-]{10,15}$"),
            validator: Validators.pattern("^[0-9]{1,10}$"),
            message: "Accept numbers only"
          },
          {
            name: "maxlength",
            length: 10,
            validator: Validators.maxLength(10),
            message: "Maximum length 10 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

    ];
    return self.formFieldConfig;
  }
  /******************************************************************************************************** */



  /*  {"ClassId":667 , "Name":"TestStudent1", "RollNo": "12345ABCD", "Standard": "Testing", "Section":"A","AdmissionNumber":"12345ABCD","Gender":"f","AcademicYear":"2019-2020","ParentName":"Raju","PrimaryContact":1212121213} */
  getInputData(standard_Sections = '') {
    var inPutFieldsJson_original_json_student: FieldConfig[] = [

      {
        type: 'input',
        inputType: "text",
        label: ' Name',
        name: 'Name',
        value: '',
        isReadOnly: false,
        required: true,
        icon: true,
        icon_name: "perm_identity",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Name Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z ]+$"),
            message: "Accept only Characters and space"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },




      {
        type: "select",
        inputType: "select",
        label: "Standard",
        name: "ClassId",
        value: "",
        isReadOnly: false,
        icon: false,
        icon_name: "",
        host_cascading: true,
        options: this.classIdJson,
        /*  options: [
           { key: '705', label: '705' },
           { key: '706', label: '706' },
           { key: '713', label: '713' },
 
         ], */

        required: true,
        divPartitionColumns: 4,
      },
      // {
      //   type: "cascade_celect",
      //   inputType: "select",
      //   label: "Standard ",
      //   name: "Standard",
      //   value: "",
      //   isReadOnly: false,
      //   icon: false,
      //   icon_name: "",
      //   cascading_DropDown: true,
      //   options: [],
      //   //   { key: 'First Year - Sem1', label: 'First Year - Sem1' },
      //   //   { key: 'Second Year', label: 'Second Year' },
      //   //   { key: 'Third Year', label: 'Third Year' },

      //   // ],
      //   required: false,
      //   divPartitionColumns: 4,
      // },

      {
        type: 'input',
        inputType: "text",
        label: ' ClassId',
        name: 'Standard',
        value: '',
        isReadOnly: false,
        required: false,
        fetchHiddenField: true,
        hiddenFieldData: [
          {
            field: "ClassId"
          }
        ],
        icon: true,
        icon_name: "perm_identity",
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

      {
        type: "select",
        inputType: "select",
        label: "Section ",
        name: "Section",
        value: "",
        isReadOnly: false,
        cascading_DropDown: [
          {
            field: "ClassId",
          },
        ],
        icon: false,
        icon_name: "",
        options: [],
        //   { key: 'COM', label: 'COM' },
        //   { key: 'CSC-A', label: 'CSC-A' },
        //   { key: 'CSC-B', label: 'CSC-B' },
        //   { key: 'ECE-A', label: 'ECE-A' },
        //   { key: 'EEE-A', label: 'EEE-A' },
        //   { key: 'CSC', label: 'CSC' },
        //   { key: 'ECE', label: 'ECE' },

        // ],
        // validations: [
        //   {
        //     name: "required",
        //     validator: Validators.required,
        //     message: "Section Required"
        //   }
        // ],
        required: false,
        divPartitionColumns: 4,
      },



      /*  {
         type: "select",
         inputType: "select",
         label: "ClassId",
         name: "ClassId",
         value: "",
         isReadOnly: false,
         cascading_DropDown: false,
         icon: false,
         icon_name: "",
         // options: this.classIdJson,
         options: [
           { key: '705', label: '705' },
           { key: '706', label: '706' },
           { key: '713', label: '713' },
 
         ],
         validations: [
           {
             name: "required",
             validator: Validators.required,
             message: "Semester Required"
           }
         ],
         required: false,
         divPartitionColumns: 4,
       },
       {
         type: "select",
         inputType: "select",
         label: "Standard ",
         name: "Standard",
         value: "",
         isReadOnly: false,
         icon: false,
         icon_name: "",
         cascading_DropDown: true,
         options: [
           { key: 'First Year - Sem1', label: 'First Year - Sem1' },
           { key: 'Second Year', label: 'Second Year' },
           { key: 'Third Year', label: 'Third Year' },
 
         ],
         required: false,
         divPartitionColumns: 4,
       },
 
       {
         type: "select",
         inputType: "select",
         label: "Section ",
         name: "Section",
         value: "",
         isReadOnly: false,
         icon: false,
         icon_name: "",
         options: [
           { key: 'COM', label: 'COM' },
           { key: 'CSC-A', label: 'CSC-A' },
           { key: 'CSC-B', label: 'CSC-B' },
           { key: 'ECE-A', label: 'ECE-A' },
           { key: 'EEE-A', label: 'EEE-A' },
           { key: 'CSC', label: 'CSC' },
           { key: 'ECE', label: 'ECE' },
 
         ],
         validations: [
           {
             name: "required",
             validator: Validators.required,
             message: "Section Required"
           }
         ],
         required: false,
         divPartitionColumns: 4,
       },
  */
      {
        type: 'input',
        inputType: "text",
        label: 'Admission Number',
        name: 'AdmissionNumber',
        value: '',
        isReadOnly: false,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Admission Number Required"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Roll Number',
        name: 'RollNo',
        value: '',
        isReadOnly: false,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Roll Number Required"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: "select",
        inputType: "select",
        label: "Academic Year ",
        name: "AcademicYear",
        value: "",
        isReadOnly: false,
        icon: false,
        icon_name: "",
        options: [
          { key: '2020-2021', label: '2020-2021' },
          { key: '2019-2020', label: '2019-2020' }
        ],
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Academic Year Required"
          }
        ],
        required: false,
        divPartitionColumns: 4,
      },
      {
        type: "radiobutton",
        inputType: "radio",
        label: "Gender",
        inline_class_name: 'form-check-inline',
        name: "Gender",
        icon: false,
        icon_name: "",
        options: [
          { key: 'm', label: 'Male' },
          { key: 'f', label: 'Female' }
        ],
        value: "",
        isReadOnly: false,
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Gender Required"
          }
        ],
        divPartitionColumns: 6,
        grid_column: 1,
        grid_row: 1,
      },

      /*  {
        type: "date",
        inputType: "date",
        label: "Date Of Birth",
        name: "dob",
        placeholder: "Date Of Birth",
        value: "",
        isReadOnly: false,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Date of Birth Required"
          }
        ],
        divPartitionColumns: 6,
        grid_column: 1,
        grid_row: 1,
      },  */

      {
        type: 'input',
        inputType: "text",
        label: 'Parent Name',
        name: 'ParentName',
        value: '',
        isReadOnly: false,
        icon: true,
        icon_name: "perm_identity",
        required: true,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "parent Name Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[a-zA-Z ]+$"),
            message: "Accept only Characters and space"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

      {
        type: 'input',
        inputType: "text",
        label: 'Primary Contact',
        name: 'PrimaryContact',
        value: '',
        isReadOnly: false,
        required: true,
        icon: true,
        icon_name: "phone",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Primary Contact Required"
          },
          {
            name: "pattern",
            validator: Validators.pattern("^[0-9-]{10,15}$"),
            message: "Accept numbers only"
          },
          {
            name: "maxlength",
            length: 50,
            validator: Validators.maxLength(50),
            message: "Maximum length 50 characters"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      /*  {
         type: 'input',
         inputType: "text",
         label: 'Secondary Contact',
         name: 'SecondaryContact',
         value: '',
         isReadOnly: false,
         icon: true,
         icon_name: "phone",
         required: true,
         validations: [
           {
             name: "pattern",
             validator: Validators.pattern("^[0-9-]{10,15}$"),
             message: "Accept numbers only"
           },
           {
             name: "maxlength",
             length: 50,
             validator: Validators.maxLength(50),
             message: "Maximum length 50 characters"
           }
         ],
         divPartitionColumns: 4,
         grid_column: 1,
         grid_row: 1
       }, */

      /* {
        type: 'file',
        inputType: "file",
        label: 'Choose Photo',
        name: 'document',
        accept: 'image/png, image/jpeg',
        required: true,
        icon: true,
        icon_name: "image",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "File Required"
          }
        ],
        divPartitionColumns: 6,
      },
*/

      /*  {
         type: "date",
         inputType: "date",
         label: "Date Of Birth (As per the birth certificate) 111",
         name: "dob",
         placeholder: "Date Of Birth",
         value: "",
         isReadOnly: false,
         required: true,
         validations: [
           {
             name: "required",
             validator: Validators.required,
             message: "Date of Birth Required"
           }
         ],
         divPartitionColumns: 6,
         grid_column: 1,
         grid_row: 1,
       },
       {
         type: "input",
         inputType: "text",
         label: "Age in Years, Months & Days",
         name: "accurate_age",
         placeholder: "Age",
         value: "",
         required: true,
         age_in_y_m_d: true,
         isReadOnly: true,
         validations: [
           {
             name: "required",
             validator: Validators.required,
             message: "Age Required"
           }
         ],
         preFieldData: [
           {
             field: "dob"
           }
         ],
         divPartitionColumns: 6,
         grid_column: 1,
         grid_row: 1,
       },
       {
         type: "input",
         inputType: "text",
         label: "Place of Birth",
         name: "place_of_birth",
         placeholder: "Place of Birth",
         value: "",
         isReadOnly: false,
         required: true,
         validations: [
           {
             name: "required",
             validator: Validators.required,
             message: "Place of birth is required"
           },
           {
             name: "pattern",
             validator: Validators.pattern("^[A-za-z ]+$"),
             message: "Accept only Characters and space"
           },
           {
             name: "maxlength",
             length: 50,
             validator: Validators.maxLength(50),
             message: "Maximum length 50 characters"
           }
         ],
         divPartitionColumns: 6,
         grid_column: 1,
         grid_row: 1,
       }, */


    ];
    return inPutFieldsJson_original_json_student;
  }


  getViewStudentInputData(standard_Sections = '') {
    var inPutFieldsJson_original_json_student: FieldConfig[] = [

      {
        type: 'input',
        inputType: "text",
        label: ' Name',
        name: 'Name',
        value: '',
        isReadOnly: true,
        required: true,
        icon: true,
        icon_name: "perm_identity",
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },




      {
        type: "select",
        inputType: "select",
        label: "Standard",
        name: "ClassId",
        value: "",
        isReadOnly: true,
        icon: false,
        icon_name: "",
        host_cascading: true,
        options: this.classIdJson,
        required: false,
        divPartitionColumns: 4,
      },
      {
        type: 'input',
        inputType: "text",
        label: ' ClassId',
        name: 'Standard',
        value: '',
        isReadOnly: true,
        required: false,
        fetchHiddenField: true,
        hiddenFieldData: [
          {
            field: "ClassId"
          }
        ],
        icon: true,
        icon_name: "perm_identity",
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

      {
        type: "select",
        inputType: "select",
        label: "Section ",
        name: "Section",
        value: "",
        isReadOnly: true,
        cascading_DropDown: [
          {
            field: "ClassId",
          },
        ],
        icon: false,
        icon_name: "",
        options: [],
        required: false,
        divPartitionColumns: 4,
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Admission Number',
        name: 'AdmissionNumber',
        value: '',
        isReadOnly: true,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Admission Number Required"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: 'input',
        inputType: "text",
        label: 'Roll Number',
        name: 'RollNo',
        value: '',
        isReadOnly: true,
        required: true,
        icon: false,
        icon_name: "",
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Roll Number Required"
          }
        ],
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },
      {
        type: "select",
        inputType: "select",
        label: "Academic Year ",
        name: "AcademicYear",
        value: "",
        isReadOnly: true,
        icon: false,
        icon_name: "",
        options: [
          { key: '2020-2021', label: '2020-2021' },
          { key: '2019-2020', label: '2019-2020' }
        ],
        required: false,
        divPartitionColumns: 4,
      },
      {
        type: "radiobutton",
        inputType: "radio",
        label: "Gender",
        inline_class_name: 'form-check-inline',
        name: "Gender",
        icon: false,
        icon_name: "",
        options: [
          { key: 'm', label: 'Male' },
          { key: 'f', label: 'Female' }],
        value: "",
        isReadOnly: true,
        required: true,
        divPartitionColumns: 6,
        grid_column: 1,
        grid_row: 1,
      },

      {
        type: 'input',
        inputType: "text",
        label: 'Parent Name',
        name: 'ParentName',
        value: '',
        isReadOnly: true,
        icon: true,
        icon_name: "perm_identity",
        required: true,
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

      {
        type: 'input',
        inputType: "text",
        label: 'Primary Contact',
        name: 'PrimaryContact',
        value: '',
        isReadOnly: true,
        required: true,
        icon: true,
        icon_name: "phone",
        divPartitionColumns: 4,
        grid_column: 1,
        grid_row: 1
      },

    ];
    return inPutFieldsJson_original_json_student;
  }



  /**
   * 
   * @param subscribedInPutValuesJson 
   * get students from api 
   */
  setStudentBy_Id(subscribedInPutValuesJson) {
    this.subscribedInPutValuesJson = subscribedInPutValuesJson
    console.log(subscribedInPutValuesJson);
  }
  getStudentBy_Id() {
    return this.subscribedInPutValuesJson;
  }
  getFormData() {

    // "residential_address":{"address_for_communication":"Kphb colony, Hyderabad-500072","permanent_address":"Ganapavaram"},
    var jsonData2 = '{"Name":"Subhadra","AdmissionNumber":"54356","RollNo":"4566","Gender":"f","ParentName":"Chikkala","PrimaryContact":"7897897999"}';
    var jsonData1 = '{"student_name":"Subhadra","semester":"I","year-semester":"Previous","admission_number":"34","roll_number":"435","academic-semester":"2019-2020","gender":"Female","dob":"1991-06-03T18:30:00.000Z","parent_name":"chikkala","primary_contact":"97897978789","secondary_contact":"789678967899"}';
    var jsonData = null;

    var objData = JSON.parse(jsonData2);
    // console.log(objData);
    var inPutValuesJson = objData;

    return inPutValuesJson;

  }
}
