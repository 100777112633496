import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class NavService {
    public appDrawer: any;
    public currentUrl = new BehaviorSubject<string>(undefined);
    /**
     * 
     * @param router 
     * @param apiService 
     */
    constructor(private router: Router, private apiService: ApiService) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl.next(event.urlAfterRedirects);
            }
        });
    }

    /**
     * API - getActionsByRole
     * @param requestParams
     * 
     * This method is use layout component file
     */
    getActionsByRole(requestParams: object) {
        /**API Method Name */
        let apiMethod = 'getActionsByRole';
        /**API Body Data */
        let apiBody = requestParams;
        /** API - POST SERVICE REQUEST */
        return this.apiService.post(apiMethod, apiBody);
    }

    public closeNav() {
        this.appDrawer.close();
    }

    public openNav() {
        this.appDrawer.open();
    }

    public toggle() {
        this.appDrawer.toggle();
    }
}
