import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';
/** Service Loading here */
import { CommonService, ExcelService } from '../_services';
/** Models Loading here */
import { StudentAttendance, Student } from "../_models";
import { GroupByPipe } from '../_custom-pipes/group-by.pipe';

/** Pdf related data load here */
import { Observable } from 'rxjs';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { UserOptions, } from 'jspdf-autotable';
import { SpeedDialFabPosition } from '../shared/speed-dial-fab';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions, afterPageContent?: any) => jsPDF;
}
/** Pdf related data load here */

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
  providers: [
    GroupByPipe
  ]
})
export class PdfComponent implements OnInit, OnChanges {

  /** Input variables */
  @Input() reportType: any;
  @Input() rowData: any;
  @Input() fileName: any;
  @Input() filterData: any;
  @Input() chartImageTag: string;
  @Input() tableHeader: string[];

  /** Input variables */
  /** PDF Related variable declare here */
  // public students: StudentAttendance[] = [];
  public students: Student[] = [];
  public footer_x: number = 375; //375, 620,
  public footer_y: number = 620;
  public imageEnd_y: number = 30;
  public htmlHeader_x: number = 10;
  public htmlHeader_y: number = this.imageEnd_y + 20; //htmlHeader_y=50;  must be greater than line_y2= 40
  public line_x2: number = 4;
  public line_y2: number = this.htmlHeader_y + 32 //htmlHeader_y=65;  must be greater than line_y2= 40
  public line_y: number = this.htmlHeader_y + 32 // must be y2=y
  public chart_y: number = this.line_y + 10 // must be y2=y
  public auto_table_startY: number = this.chart_y + 260;
  public columns: string[] = ['StudentName', 'RollNo', 'Attendance'];
  public rest_brackPointcount = 0;
  public pagenumber = 1;
  public rowsData: string[] = [];
  public majorRowsData: string[][] = [];
  public images = this.commonService.pdfHeader_logo_content();
  public lineHeight: number;
  public postionY: number
  /** PDF Related variable declare here */



  /******************************************************************************************** 
  * speed Dial Fab Buttons code atart here
  * 
  */
  public speedDialFabButtons = [
    // {
    //   icon: 'cloud_download',
    //   color: 'primary',
    //   class: "fab-primary",
    //   tooltip: "Download Reports as PDF",
    //   tooltipPosition: "before",
    //   tooltipClass: "",
    //   event: "downloadPDF",
    //   eventParams: ""
    // },
    {
      icon: 'cloud_download',
      color: 'primary',
      class: "fab-primary",
      tooltip: "Download Reports as Excel",
      tooltipPosition: "before",
      tooltipClass: "",
      event: "exportXls",
      // event: (this.reportType === 'dateWise') ? 'exportRecord' : 'exportRecordMultiSheet',
      eventParams: ""
    },
  ];

  SpeedDialFabPosition = SpeedDialFabPosition;
  speedDialFabColumnDirection = 'column-reverse';
  speedDialFabPosition = SpeedDialFabPosition.Bottom;
  speedDialFabPositionClassName = 'speed-dial-container-bottom';

  /**
   * 
   * @param commonService 
   * @param elementRef 
   */
  constructor(private commonService: CommonService, private groupbyPipe: GroupByPipe, private excelService: ExcelService, private elementRef: ElementRef) { }

  ngOnInit() {
    // this.students = this.rowData;
    // console.log("this.studentss", this.students);
  }
  public reportColumns = [];

  ngOnChanges() {
    this.filterData = this.filterData;
    this.students = this.rowData;
    this.reportColumns = this.tableHeader;
    this.fileName = this.fileName.replace(/[^a-zA-Z0-9\-]/g, "");
    this.reportType = this.reportType;
    // console.log("<< rowData  >>", this.reportType);
  }

  exportXls() {
    if (this.reportType === 'dateWise') {
      this.exportRecord();
    } else {
      this.exportRecordMultiSheet();
    }
  }

  /**
   *  Multi sheet excel function   * 
   */
  public exportRecordMultiSheet(): void {
    if (this.students.length > 0) {
      var groupObj = {};
      this.students && this.students.forEach(rows => {
        let dateVal = rows['dateStr'];
        var tableRows = [];
        rows['list'] && rows['list'].forEach(row => {
          let eachRow = {};
          this.reportColumns && this.reportColumns.forEach(col => {
            eachRow[col] = row[col];
          });
          tableRows.push(eachRow);
        });
        groupObj[dateVal] = tableRows;
      });

      /** Excel Sheet Config here */
      var XLConfig = {
        multiSheet: true,
        skipHeader: false,
        header: [],
        rows: groupObj,
        fileName: this.fileName + ".xlsx",
      }
      /** Calling Excelsheet function here */
      this.excelService.jsonToExcelSheet(XLConfig);

    } else {
      return;
    }
  }

  /**
   *  Single sheet excel function   * 
   */
  public exportRecord(): void {
    if (this.students.length > 0) {
      var tableRows = [];
      this.students && this.students.forEach(row => {
        let eachRow = {};
        this.reportColumns && this.reportColumns.forEach(col => {
          eachRow[col] = row[col];
        });
        tableRows.push(eachRow);
      });

      /** Excel Sheet Config here */
      var XLConfig = {
        multiSheet: false,
        skipHeader: false,
        header: this.reportColumns,
        rows: tableRows,
        fileName: this.fileName + ".xlsx",
      }
      /** Calling Excelsheet function here */
      this.excelService.jsonToExcelSheet(XLConfig);

    } else {
      return;
    }
  }


  /*******************************************************************************************
   * onSpeedDialFabClicked
   * @param resData 
   */
  onSpeedDialFabClicked(resData: any) {
    //console.log(resData);
    // Function name to invoke
    var fnName = resData.event;
    // Params to pass to the function
    var params = resData.eventParams
    // Call function
    this[fnName](params);
    //eval(function_name);
  }

  /**
   * download PDF  
   */
  public downloadPDF() {
    let resData = this.rowData;
    // console.log(resData);
    // console.log(this.filterData);
    var columns: string[] = ['S.No', 'StudentName', 'RollNo', 'Attendance'];
    const image = {
      url: '../assets/images/logo/logo.png',
      context: 'Mahila self help group'
    }
    this.getImageDimension(image).subscribe(imageDimensions => {
      this.responseData(resData, columns, imageDimensions.width);
    });
  }

  /**
   * PDF gerenate function
   * @param resd 
   * @param cols 
   * @param imageDimensions_width 
   */

  responseData(resd, cols, imageDimensions_width) {
    var totalPagesExp = "{total_pages_count_string}";
    let rest_brackPoint: any = (resd.length / 25).toFixed()
    let objectKeys = Object.keys;
    let doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    this.lineHeight = doc.getLineHeight();
    var lineHeight = doc.getLineHeight()
    var pageSize = doc.internal.pageSize;


    // this.footer(doc, this.pagenumber);
    let imageData = this.getBase64Image(document.getElementById('img'));
    let pageCount = 0
    let numberOfStudents = 0
    resd.forEach(rowData => {
      pageCount++
      numberOfStudents++;
      let studentcount = numberOfStudents.toString();
      let tableHeaders: [] = [];
      // Object.keys(rowData)
      // this.head(doc, imageData, imageDimensions_width);
      this.rowsData = [studentcount, rowData.StudentName, rowData.RollNo, rowData['Present/Absent']];
      this.majorRowsData.push(this.rowsData);
    });

    let filterCount: number = 0

    /* dynamic header and top page content as wrap text*/
    let contentDetails = this.filterData
    this.postionY = this.line_y2 + 20
    // console.log(this.postionY, "position");
    objectKeys(contentDetails).forEach(filData => {
      var pageWidth = pageSize.width ? pageSize.width / 3 : pageSize.getWidth() / 3;
      if (!contentDetails[filData] == null || contentDetails[filData] !== '' && filData !== 'classId') {
        var filDataSplitted = doc.splitTextToSize(filData.toString().toUpperCase(), pageWidth - 35, {});
        var filValueSplitted = doc.splitTextToSize(contentDetails[filData].toString(), pageWidth - 35, {});
        var fil_lines = filDataSplitted.length
        // console.log(fil_lines, "fil_lines")
        var fil_value_lines = filValueSplitted.length
        // console.log(fil_value_lines, "fil_value_lines")
        var fil_blockHeight = fil_lines * 12
        // console.log(fil_blockHeight, "fil_blockHeight")
        var fil_value_blockHeight = fil_value_lines * 12
        // console.log(fil_value_blockHeight, "fil_value_blockHeight")
        /* right align */
        doc.setFontType('bold');
        doc.setFontSize(9).setTextColor(5, 103, 94).text(310, this.postionY, filDataSplitted + "  ");
        doc.setFontType('normal');
        doc.setFontSize(9).setTextColor(5, 103, 94).text(370, this.postionY, " : " + filValueSplitted);

        /* left aline */
        doc.setFontType('bold');
        doc.setFontSize(9).setTextColor(5, 103, 94).text(25, this.postionY, filDataSplitted + "  ");
        doc.setFontType('normal');
        doc.setFontSize(9).setTextColor(5, 103, 94).text(75, this.postionY, " : " + filValueSplitted);
        if (fil_blockHeight > fil_value_blockHeight || fil_blockHeight == fil_value_blockHeight) {
          this.postionY += fil_blockHeight;
          // console.log(this.postionY);
        }
        else if (fil_blockHeight < fil_value_blockHeight) {
          this.postionY += fil_value_blockHeight;
          // console.log(this.postionY);
        }
      }
    });

    doc.setTextColor(230, 20, 80).text("Total Attendance Details ", 15, 90);
    /** chart image */
    // var imageTags = this.chartImageTag;
    // console.log(this.chartImageTag);
    // let chartImg = document.getElementById(this.chartImageTag) as HTMLElement;
    let chartImg = document.querySelector("#" + this.chartImageTag + " img") as Element;
    // console.log(document.querySelector("#" + this.chartImageTag + " img") as Element);

    var imageTags = chartImg;
    doc.addImage(imageTags, 'png', 0, this.postionY + 50, 350, 250, undefined, 'NONE');
    this.auto_table_startY = this.auto_table_startY + this.postionY;
    /** chart image */


    /** autoTable start here */
    let self = this;
    doc.autoTable({
      head: [cols], // head: [self.tableHeader],
      body: this.majorRowsData,
      startY: this.auto_table_startY,
      didDrawPage: function (data) {
        // Header

        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle('normal');
        // if (base64Img) {
        // console.log(self.imageEnd_y);

        /* Header text objectb with wraping*/


        var pageSize = doc.internal.pageSize;
        let headercontentDetails = self.filterData
        self.postionY = 15
        objectKeys(headercontentDetails).forEach(filData => {
          var pageWidth = pageSize.width ? pageSize.width / 3 : pageSize.getWidth() / 3;
          if (!headercontentDetails[filData] == null || headercontentDetails[filData] !== '' && filData !== 'classId') {
            var filDataSplitted = doc.splitTextToSize(filData.toString().toUpperCase(), pageWidth - 35, {});
            var filValueSplitted = doc.splitTextToSize(headercontentDetails[filData].toString(), pageWidth - 35, {});
            var fil_lines = filDataSplitted.length
            // console.log(fil_lines, "fil_lines")
            var fil_value_lines = filValueSplitted.length
            // console.log(fil_value_lines, "fil_value_lines")
            var fil_blockHeight = fil_lines * 12
            // console.log(fil_blockHeight, "fil_blockHeight")
            var fil_value_blockHeight = fil_value_lines * 12
            // console.log(fil_value_blockHeight, "fil_value_blockHeight")
            /* right align */
            doc.setFontType('bold');
            doc.setFontSize(9).setTextColor(5, 103, 94).text(310, self.postionY, filDataSplitted + "  ");
            doc.setFontType('normal');
            doc.setFontSize(9).setTextColor(5, 103, 94).text(370, self.postionY, " : " + filValueSplitted);

            /* left aline */
            doc.setFontType('bold');
            doc.setFontSize(9).setTextColor(5, 103, 94).text(25, self.postionY, filDataSplitted + "  ");
            doc.setFontType('normal');
            doc.setFontSize(9).setTextColor(5, 103, 94).text(75, self.postionY, " : " + filValueSplitted);

            if (fil_blockHeight > fil_value_blockHeight || fil_blockHeight == fil_value_blockHeight) {
              self.postionY += fil_blockHeight;
              // console.log(self.postionY);
            }
            else if (fil_blockHeight < fil_value_blockHeight) {
              self.postionY += fil_value_blockHeight;
              // console.log(self.postionY);
            }
          }
        });

        let filterCount = 0;
        // without wraping text
        doc.addImage(imageData, "JPG", 180, 8, 70, self.imageEnd_y);

        // let htmltag = "<div>Phone: 1234567890, Email: test@gmail.com</div>"
        // doc.fromHTML(htmltag, self.htmlHeader_x, self.htmlHeader_y);
        // doc.line(435, self.line_y, 10, self.line_y2); //header line
        doc.line(160, self.line_y, 10, self.line_y2); //header line
        doc.setFontSize(14).setTextColor(5, 103, 94).text("Attendance Details", 180, self.line_y2);
        doc.line(280, self.line_y, 430, self.line_y2);
        // doc.line(435, 610, 10, 610); //footer line
        // doc.text("Report", 20 + 15, 20);

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // console.log(pageSize.getHeight());
        // console.log(pageSize.height);
        // doc.text(str, 20, pageHeight - 10);
        doc.line(435, pageHeight - 70, 10, pageHeight - 70); //footer line
      
        /* footer long text content start */
        let content = "Oakridge International School is an international baccalaureate affiliated school located in Hyderabad, India. Oakridge International School provides the IBDP and ";
        // var lineHeight = doc.getLineHeight()
        // var pageSize = doc.internal.pageSize;
        var pageWidth_footer = pageSize.width ? pageSize.width : pageSize.getWidth();
        var textSplitted = doc.splitTextToSize(content, pageWidth_footer - 35, {});
        // var lines = textSplitted.length
        // var lineHeight = doc.text(textSplitted, 14, 30).getLineHeight();
        // var blockHeight = lines * lineHeight
        var yPos = pageHeight - 60;
        var xPosLeft = 15;
        var xPosRight = 310;
        doc.text(xPosLeft, yPos, textSplitted)

        /* footer middle */
        let footerAddress = "Hyderabad, Gachibowli - Oakridge International School"
        var pageWidth_footer = pageSize.width ? pageSize.width / 2 : pageSize.getWidth() / 2;
        var moddlefooterSplitted = doc.splitTextToSize(footerAddress, pageWidth_footer - 40, {});
        doc.setFontType('bold');
        doc.setTextColor(37, 14, 214).text(100, pageHeight - 30, moddlefooterSplitted)
        /* footer long text content end */

        doc.text(str, 360, pageHeight - 10);// page count at footer
      },
      // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
      // This hook is called just before the column width and other features are computed. raw: (4) ["306", "Sony", "E-36", "Absent"]
      didParseCell: function (data) {
        // console.log(data);
        if (data.row.raw[3] == "Absent") {
          data.cell.styles.fillColor = [40, 170, 100];

        }
        if (data.column.dataKey == 0) {
          data.cell.styles.fillColor = [0, 150, 136];
        }
      },
      // Use for changing styles with jspdf functions or customize the positioning of cells or cell text
      // just before they are drawn to the page.
      willDrawCell: function (data) {
        if (data.row.section == 'body' && data.column.dataKey == 2) {
          if (data.cell.raw == "E-03") {
            doc.setTextColor(218, 19, 201); // Red
            doc.setFontStyle('bold');
          }
        }
      },
      margin: { top: 95, bottom: 80 }
    });
    /** autoTable end here */


    /** Total page number plugin only available in jspdf v1.0+*/
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    /** Total page number plugin only available in jspdf v1.0+*/

    /*** Download on system */
    doc.save('Studenttable.pdf');
  }

  /**
   * Image convert to binary code
   * @param img 
   */
  getBase64Image(img) {
    // console.log("image", img);
    var canvas = document.createElement("canvas");
    // console.log("image");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }

  /**
   * Image load here
   * @param image 
   */
  getImageDimension(image): Observable<any> {
    return new Observable(observer => {
      const img = new Image();
      img.onload = function (event) {
        const loadedImage: any = event.currentTarget;
        image.width = loadedImage.width;
        image.height = loadedImage.height;
        observer.next(image);
        observer.complete();
      }
      img.src = image.url;
    });
  }

  /* text print in next block with loop by incresing x and y */
  responseData_text_next(resd, cols, imageDimensions_width) {
    var totalPagesExp = "{total_pages_count_string}";
    let rest_brackPoint: any = (resd.length / 25).toFixed()

    let doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    this.lineHeight = doc.getLineHeight();
    // this.footer(doc, this.pagenumber);
    let imageData = this.getBase64Image(document.getElementById('img'));
    let pageCount = 0
    let numberOfStudents = 0
    resd.forEach(rowData => {
      pageCount++
      numberOfStudents++;
      let studentcount = numberOfStudents.toString();
      let tableHeaders: [] = [];
      // Object.keys(rowData)
      // this.head(doc, imageData, imageDimensions_width);
      this.rowsData = [studentcount, rowData.StudentName, rowData.RollNo, rowData['Present/Absent']];
      this.majorRowsData.push(this.rowsData);
    });
    let objectKeys = Object.keys;
    let filterCount: number = 0
    // this.postionY += (this.line_y2+this.lineHeight.toFixed());
    objectKeys(this.filterData).forEach(filData => {
      // this.postionY += this.lineHeight.toFixed();
      filterCount++
      if (this.filterData[filData] == null || this.filterData[filData] == '') {
        filterCount = filterCount - 1
      }
      if (!this.filterData[filData] == null || this.filterData[filData] !== '' && filData !== 'classId') {

        doc.setFontSize(9).setTextColor(5, 103, 94).text(filData.toString().toUpperCase() + "     :" + "    " + this.filterData[filData].toString(), 15, this.line_y2 + filterCount * 12);
        // console.log(filData);
        //  filData.toString().toUpperCase()+":"+"  "+this.filterData[filData].toString()
        // console.log(filData.toUpperCase(), this.filterData[filData]);
      }
      this.postionY = this.line_y2 + filterCount * 12
    });
    objectKeys(this.filterData).forEach(filData => {
      // this.postionY += this.lineHeight.toFixed();
      filterCount++
      if (this.filterData[filData] == null || this.filterData[filData] == '') {
        filterCount = filterCount - 1
      }
      if (!this.filterData[filData] == null || this.filterData[filData] !== '' && filData !== 'classId') {

        doc.setFontSize(9).setTextColor(5, 103, 94).text(filData.toString().toUpperCase() + "     :" + "    " + this.filterData[filData].toString(), 200, this.line_y2 + filterCount * 12);
        // console.log(filData);
        //  filData.toString().toUpperCase()+":"+"  "+this.filterData[filData].toString()
        // console.log(filData.toUpperCase(), this.filterData[filData]);
      }
      this.postionY = this.line_y2 + filterCount * 12
    });
    /** chart image */
    var imageTags = this.chartImageTag;
    doc.addImage(imageTags, 'png', 100, this.postionY + 10, 0, 0, undefined, 'NONE');
    this.auto_table_startY = this.auto_table_startY + this.postionY;
    /** chart image */

    /** autoTable start here */

    let self = this;
    doc.autoTable({
      head: [cols], // head: [self.tableHeader],
      body: this.majorRowsData,
      startY: this.auto_table_startY,
      didDrawPage: function (data) {
        // Header

        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle('normal');
        // if (base64Img) {
        // console.log(self.imageEnd_y);

        doc.addImage(imageData, "JPG", 180, 8, 70, self.imageEnd_y);
        // let htmltag = "<div>Phone: 1234567890, Email: test@gmail.com</div>"
        // doc.fromHTML(htmltag, self.htmlHeader_x, self.htmlHeader_y);
        doc.line(435, self.line_y, 10, self.line_y2); //header line
        // doc.line(435, 610, 10, 610); //footer line
        // doc.text("Report", 20 + 15, 20);

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // console.log(pageSize.getHeight());
        // console.log(pageSize.height);
        // doc.text(str, 20, pageHeight - 10);
        doc.line(435, pageHeight - 20, 10, pageHeight - 20); //footer line

        doc.text(str, 350, pageHeight - 10);
      },
      // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
      // This hook is called just before the column width and other features are computed. raw: (4) ["306", "Sony", "E-36", "Absent"]
      didParseCell: function (data) {
        // console.log(data);
        if (data.row.raw[3] == "Absent") {
          data.cell.styles.fillColor = [40, 170, 100];

        }
        if (data.column.dataKey == 0) {
          data.cell.styles.fillColor = [0, 150, 136];
        }
      },
      // Use for changing styles with jspdf functions or customize the positioning of cells or cell text
      // just before they are drawn to the page.
      willDrawCell: function (data) {
        if (data.row.section == 'body' && data.column.dataKey == 2) {
          if (data.cell.raw == "E-03") {
            doc.setTextColor(218, 19, 201); // Red
            doc.setFontStyle('bold');
          }
        }
      },
      margin: { top: 85 }
    });
    /** autoTable end here */

    /** Total page number plugin only available in jspdf v1.0+*/
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    /** Total page number plugin only available in jspdf v1.0+*/

    /*** Download on system */
    doc.save('Studenttable.pdf');
  }

}
