export class Employee {
    _id: string;
    Id: number;
    Name: string;
    EmployeeId: string;
    Gender?: string;
    Address?: string;
    ContactEmail?: string;
    PrimaryContact: string;
    SecondaryContact?: number;
    EmployeeType?: string;
    EmployeeRole?: string;
    Country?: string;
    State?: string;
    City?: boolean;
    Pincode?: boolean;
    Images?: [];
    // ImagePath?: string;
    // PersonId?: string;
} 