import { Injectable, HostBinding } from '@angular/core';
import { User, Role } from "../_models";
import { Constants } from "../shared/constants";
import { OverlayContainer } from "@angular/cdk/overlay";
import { FormBuilder, FormArray, Validators, FormGroup, FormControl, Form } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
const THEME_DARKNESS_SUFFIX = `-dark`;
import { MatChipInputEvent, MatChipList, MatSnackBar } from '@angular/material';
import { MessageSnackbarComponent } from "../shared/snackbars";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    /**Breadcrumbs Variables */
    BREAD_CRUMB_HOME_ROUTER_LINK: String = '';
    BREAD_CRUMB_HOME_NAME: String = '';
    PAGE_TITLE: String = '';
    /**Breadcrumbs Variables */
    loginUser: User;
    loginUserRole: Role[] = [];
    actionBtnStatus: boolean = true;
    public pdfHeadeLogoContent: any[] = [];
    /* PDF HEader Logo */

    /** Theme related variable here */
    // @HostBinding('class') activeThemeCssClass: string;
    activeThemeCssClass: string;
    themes: string[] = [
        "deeppurple-amber",
        "indigo-pink",
        "pink-bluegrey",
        "purple-green",
        "teal-indigo-pink",
    ];
    isThemeDark = false;
    activeTheme: string;
    /** Theme related variable here */

    /**
     * 
     * @param overlayContainer 
     */
    constructor(private snackBar: MatSnackBar, private overlayContainer: OverlayContainer) {

        /**Breadcrumbs Variables Value Assign here */
        this.BREAD_CRUMB_HOME_ROUTER_LINK = Constants.homeRouterLink;
        this.BREAD_CRUMB_HOME_NAME = Constants.homeName
        this.PAGE_TITLE = "";
        /**Breadcrumbs Variables Value Assign here */
    }

    /**
     * 
     * @param theme 
     * @param darkness 
     */
    setTheme(theme: string, darkness: boolean = null) {

        /************************************************************************** */
        if (darkness === null)
            darkness = this.isThemeDark;
        else if (this.isThemeDark === darkness) {
            if (this.activeTheme === theme) return;
        } else
            this.isThemeDark = darkness;

        this.activeTheme = theme;
        /************************************************************************** */

        const cssClass = darkness === true ? theme + THEME_DARKNESS_SUFFIX : theme;

        /************************************************************************** */
        const classList = this.overlayContainer.getContainerElement().classList;
        if (classList.contains(this.activeThemeCssClass))
            classList.replace(this.activeThemeCssClass, cssClass);
        else
            classList.add(cssClass);
        /************************************************************************** */

        /** Get class list from element through */
        let dom = document.getElementById("app-root-id") as HTMLElement;
        /** Remove Class (existing class remove here) */
        dom.classList.remove(this.activeThemeCssClass);
        /** Theme change here */
        this.activeThemeCssClass = cssClass;
        /** Add Class (new class add here)  */
        dom.classList.add(this.activeThemeCssClass);

        console.log("--------------------------------------");
        return this.activeThemeCssClass;
    }

    /**
     * dark theme active here
     */
    toggleDarkness() {
        this.setTheme(this.activeTheme, !this.isThemeDark);
    }

    /**
     * 
     * @param routerLinks 
     */
    changeRouterLinks(routerLinks) {
        var i = 0;
        var newArr: any = [];

        let child: any = {};

        // child['Name'] = "Sub " + i;
        // child['RouterLink'] = "/layout/students";
        // child['DisplayName'] = "Sub " + i;
        // child['TabOrder'] = i;
        // child['Icon'] = "people";

        routerLinks && routerLinks.forEach(item => {

            // let RouteStateReplace = item.RouteState.replace('.', '/');
            // let route: string = RouteStateReplace;
            let route: string = item.RouteState;
            let result = "#";
            let matIcon = "list";

            switch (route) {
                case "layout.students":
                    result = Constants.student;
                    matIcon = 'people_outline';
                    break;
                case "layout.admindashboard":
                    result = Constants.dashboard;
                    matIcon = 'dashboard';
                    break;
                case "layout.attendance":
                    result = Constants.attendance;
                    matIcon = 'insert_chart_outlined';
                    break;
                case "layout.configurationreports":
                    result = Constants.configurationreports;
                    matIcon = 'insert_chart_outlined';
                    break;
                case "layout.changepassword":
                    result = Constants.changepassword;
                    matIcon = 'vpn_key';
                    break;
                case "layout.employees":
                    result = Constants.employee;
                    matIcon = 'people_outline';
                    break;
                case "layout.employeeattendance":
                    result = Constants.employeeattendance;
                    matIcon = 'insert_chart_outlined';
                    break;
                case "layout.visitorentries":
                    result = Constants.visitorentries;
                    matIcon = 'insert_chart_outlined';
                    break;
                case "layout.employeePhotos":
                    result = Constants.employeePhotos;
                    matIcon = 'insert_chart_outlined';
                    break;
            }

            item.RouterLink = result;
            item.matIcon = matIcon;

            if (i == 1) {
                child['Name'] = "Sub " + i;
                child['RouterLink'] = item.RouterLink;
                child['DisplayName'] = "Sub " + i;
                child['TabOrder'] = i;
                child['Icon'] = "people";
                newArr[0] = child;
            }

            //item['children'] = newArr;
            i++;
        });
        // console.log(routerLinks);
        return routerLinks;
    }

    getUseRoles() {
        let roleObj = {};
        this.loginUserRole.forEach(function (element) {
            roleObj[element.Name] = element.Id;
        });
        // console.log(roleObj);
        return roleObj;
    }

    getUseRoleNames() {
        let roleNameArr = [];
        let i = 0;
        this.loginUserRole.forEach(function (element) {
            roleNameArr[i] = element.Name;
            i++;
        });
        //console.log(roleNameArr);
        return roleNameArr;
    }

    getUseRoleIds() {
        let roleIdArr = [];
        let i = 0;
        this.loginUserRole.forEach(function (element) {
            roleIdArr[i] = element.Id;
            i++;
        });
        // console.log(roleIdArr);
        return roleIdArr;
    }

    /**
     * loggedInUser Config
     */
    private loggedInUserObsble = new BehaviorSubject("");
    public loggedInUserDetails = this.loggedInUserObsble.asObservable();

    /**
     * Set input config object for loggedInUser
     * @param data 
     */

    _orgIds: any;
    _loggedInUser: any;
    public setloggedInUser(data) {
        this._loggedInUser = data;
        this._orgIds = {
            OrganizationId: data.OrganizationId,
            ZoneId: data.ZoneId,
            BranchId: data.BranchId
        }
        this.loggedInUserObsble.next(data);
    }
    /****end*************** */

    changeClassesObjForTeacher(classesObj) {
        let reponse = [];
        /** Change Classes object for standard and section dropdown purpose */
        classesObj.forEach(item => {
            let newObj = {}
            newObj['Id'] = item.ClassId;
            newObj['Name'] = item.Standard;
            newObj['Sections'] = [];
            for (let val of item.Sections) {
                let secObj = {}
                secObj['Name'] = val;
                newObj['Sections'].push(secObj);
            }
            reponse.push(newObj);
        });
        return reponse;
        /** end  */
    }


    /**
     * check validation in form 
     * @param formGroup 
     */
    checkValidationInFormFields(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsTouched({ onlySelf: true });
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                control.markAsTouched({ onlySelf: true });
                this.checkValidationInFormFields(control);
            } else if (control instanceof FormArray) {
                control.markAsTouched({ onlySelf: true });
                this.checkValidationInFormFields(control);
            }
        });
    }

    /******************************************************************************************* 
      * SnackBar code
      * @param message 
      * @param durationInSeconds 
      */
    ToastMsg(message: string, durationInSeconds?: number) {
        let durationInSecondsVal = (durationInSeconds) ? durationInSeconds : 2;
        this.snackBar.openFromComponent(MessageSnackbarComponent, {
            data: message,
            duration: durationInSecondsVal * 1000,
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }


    pdfHeader_logo_content() {
        return this.pdfHeadeLogoContent = [{
            url: "../assets/images/logo/logo.png",//"../assets/images/logo/logo.png",// 
            content: "College",
        }];
    }

}
