import { Component, NgZone, OnInit, Input, ViewChild, ElementRef } from "@angular/core";

/** Amcharts related files loading start here */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// Importing themes
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.useTheme(am4themes_animated);
/** Amcharts related files loading end here */

@Component({
  selector: 'app-amcharts',
  templateUrl: './amcharts.component.html',
  styleUrls: ['./amcharts.component.scss']
})
export class AmchartsComponent implements OnInit {

  @ViewChild('chartRefId') chartRefId: ElementRef;
  // @ViewChild('chartRefIdTest') chartRefIdTest: ElementRef;
  public chartRefIdElement;
  // public chartRefIdTestE;
  public chart_img_div;
  public chart_img_data;

  /** Private Variables for chart type function start here */
  private StackedColumnChart: am4charts.XYChart;
  private StackedColumnChart3D: am4charts.XYChart3D;
  private PieChart: am4charts.PieChart;
  private PieChartVariableRadius: am4charts.PieChart;
  private PieChart3D: am4charts.PieChart3D;
  private PieChart3DVariableHeight: am4charts.PieChart3D;
  private XYChart: am4charts.XYChart;
  /** Private Variables for chart type function end here */

  /** Gobal Variable start here */
  public chartTypeArray: any[] = [
    { type: "StackedColumnChart", name: "100% Stacked Column Chart" },
    { type: "StackedColumnChart3D", name: "100% 3D Stacked Column Chart" },
    { type: "PieChart", name: "Simple Pie Chart" },
    { type: "PieChartVariableRadius", name: "Variable Radius Pie Chart" },
    { type: "PieChart3D", name: "3D Pie Chart" },
    { type: "PieChart3DVariableHeight", name: "Variable Height 3D Pie Chart" },
  ];
  public chartDivID: string;
  public chartImageID: string;
  public chartTypeValue: string;
  public chartConfigData: any[];
  public chartSeriesConfigData: any;

  /** Gobal Variable start here */

  /** Input Variable start here */
  @Input() chartType: string;
  @Input() chartWidth: number;
  @Input() chartHeight: number;
  @Input() chartConfig: any[];
  @Input() chartSeriesConfig: any;
  @Input() chartTypeDropdownList: any[];
  @Input() dataFromParentComp: any;

  /** Input Variable end here */


  private PieChart1: am4charts.PieChart;

  /**
   * 
   * @param zone 
   * @param elementRef 
   */
  constructor(private zone: NgZone, private elementRef: ElementRef) { }

  /**
   * Page loading time calling data here
   */
  ngOnInit() {
    // console.log('----------ngOnInit--------start----', this.chartTypeValue, this.chartRefIdElement);
  }

  /**
   * input update in gobal variable here 
   */
  ngOnChanges(): void {
    // console.log('----------ngOnChanges--------start----', this.chartTypeValue, this.chartRefIdElement);
    // console.log("chartConfig", this.chartConfig)
    this.chartTypeArray = this.chartTypeDropdownList;
    this.chartTypeValue = this.chartType;
    this.chartDivID = this.chartType + '-ID';
    this.chartImageID = this.chartType + '-Image';
    this.chartConfigData = this.chartConfig;
    this.chartSeriesConfigData = this.chartSeriesConfig;

    this.chartRefIdElement = this.chartRefId.nativeElement;
    this.chart_img_div = this.elementRef.nativeElement.querySelector('#' + this.chartImageID);
    // this.chartRefIdTestE = this.chartRefIdTest.nativeElement;

    // console.log('----------ngOnChanges---------end---', this.chartTypeValue, this.chartRefIdElement);

    this.onChangeChartTypeData(this.chartType);

  }

  /**
   * chart loading after page load time
   */
  ngAfterViewInit(): void {
    // console.log('----------ngAfterViewInit----------start--', this.chartTypeValue, this.chartRefIdElement);

    this.chartRefIdElement = this.chartRefId.nativeElement;
    this.chart_img_div = this.elementRef.nativeElement.querySelector('#' + this.chartImageID);
    // console.log(this.chart_img_div);

    // this.chart_img_div.innerHTML = "test data";
    // this.chartRefIdTestE = this.chartRefIdTest.nativeElement;

    /* if (this.chartTypeValue == "PieChart") {
      this.test(this.chartConfigData, this.chartSeriesConfigData);
    } else {
      this.xyChartFn(this.XYChart, this.chartRefIdElement);
    }
 */

    this.onChangeChartTypeData(this.chartType);
    // console.log('----------ngAfterViewInit---------END---', this.chartTypeValue, this.chartRefIdElement);


  }

  test(chartConfig, chartSeriesConfigData) {
    this.zone.runOutsideAngular(() => {

      // Create chart instance
      let chart1 = am4core.create(this.chartRefIdElement, am4charts.PieChart);

      // Add data
      // chart1.data = chartConfig;
      // Add data
      chart1.data = [{
        "country": "Lithuania",
        "litres": 501.9
      }, {
        "country": "Czech Republic",
        "litres": 301.9
      }, {
        "country": "Ireland",
        "litres": 201.1
      }, {
        "country": "Germany",
        "litres": 165.8
      }, {
        "country": "Australia",
        "litres": 139.9
      }, {
        "country": "Austria",
        "litres": 128.3
      }, {
        "country": "UK",
        "litres": 99
      }, {
        "country": "Belgium",
        "litres": 60
      }, {
        "country": "The Netherlands",
        "litres": 50
      }];
      // Add and configure Series
      let pieSeries1 = chart1.series.push(new am4charts.PieSeries());
      pieSeries1.dataFields.value = "litres";
      pieSeries1.dataFields.category = "country";
      pieSeries1.slices.template.stroke = am4core.color("#fff");
      pieSeries1.slices.template.strokeWidth = 2;
      pieSeries1.slices.template.strokeOpacity = 1;

      // This creates initial animation
      pieSeries1.hiddenState.properties.opacity = 1;
      pieSeries1.hiddenState.properties.endAngle = -90;
      pieSeries1.hiddenState.properties.startAngle = -90;

      this.PieChart1 = chart1;
    });
  }

  /**
   * On Change chart type upadte
   * @param event 
   */
  onChangeChartTypeData(event) {
    // console.log('-------------onChangeChartTypeData-------------start---', event);

    if (event == "StackedColumnChart") {
      this.stackedColumnChartFn(this.StackedColumnChart, this.chartRefIdElement);
    } else if (event == "StackedColumnChart3D") {
      this.stackedColumnChart3DFn(this.StackedColumnChart3D, this.chartRefIdElement);
    } else if (event == "PieChart") {
      this.pieChartFn(this.PieChart, this.chartRefIdElement);
    } else if (event == "PieChartVariableRadius") {
      this.pieChartVariableRadiusFn(this.PieChartVariableRadius, this.chartRefIdElement);
    } else if (event == "PieChart3D") {
      this.pieChart3DFn(this.PieChart3D, this.chartRefIdElement);
    } else if (event == "PieChart3DVariableHeight") {
      this.pieChart3DVariableHeightFn(this.PieChart3DVariableHeight, this.chartRefIdElement);
    } else {
      this.pieChartFn(this.PieChart, this.chartRefIdElement);
    }
    // console.log('-------------onChangeChartTypeData-------------END---', event);
  }

  /*************************************************************************************************************/
  /**                                               Chart Type Function Start Here                            **/
  /*************************************************************************************************************/
  /**
   * 
   * @param chartTypeClass 
   */
  stackedColumnChartFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {

      // Create chart instance      
      let chart11 = am4core.create(chartId, am4charts.XYChart);
      // chart.width = am4core.percent(100);
      // chart.height = am4core.percent(100);
      chart11.hiddenState.properties.opacity = 0; // this creates initial fade-in
      // And, for a good measure, let's add a legend
      // chart11.legend = new am4charts.Legend();

      chart11.colors.list = [
        am4core.color("#7ddc67"),
        am4core.color("#dc6967"),
      ];

      //Add Data
      chart11.data = this.chartConfig;

      chart11.colors.step = 2;
      chart11.padding(30, 30, 10, 30);
      // chart.legend = new am4charts.Legend();

      let categoryAxis = chart11.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "day";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart11.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.minWidth = 50;
      // if (this.chartConfig && this.chartConfig['isVisitorEntries'] == true) {
      //   this.createSeriesPercentage(chart11, "normalPercentage", "Normal Percentage(%)", "day");
      //   this.createSeriesPercentage(chart11, "highPercentage", "High Percentage(%)", "day");
      // } else {
      //   this.createSeriesPercentage(chart11, "presentPercentage", "Present Percentage(%)", "day");
      //   this.createSeriesPercentage(chart11, "absentPercentage", "Absent Percentage(%)", "day");
      // }
      
      this.createSeriesPercentage(chart11, this.dataFromParentComp['fieldname1'], this.dataFromParentComp['displayfieldname1'], "day");
        this.createSeriesPercentage(chart11,this.dataFromParentComp['fieldname2'], this.dataFromParentComp['displayfieldname2'], "day");


      // chart11.scrollbarX = new am4core.Scrollbar();

      chartTypeClass = chart11;
    });
  }
  createSeriesPercentage(chart, field, name, category) {

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.width = am4core.percent(80);
    series.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";

    series.name = name;
    series.dataFields.categoryX = category;
    series.dataFields.valueY = field;

    series.dataFields.valueYShow = "totalPercent";
    series.dataItems.template.locations.categoryX = 0.5;
    series.stacked = true;
    series.tooltip.pointerOrientation = "vertical";

    let bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet.label.fill = am4core.color("#ffffff");
    bullet.locationY = 0.5;
  }

  /**
   * 
   * @param chartTypeClass 
   */
  stackedColumnChart3DFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {

      // Create chart instance
      let chart22 = am4core.create(chartId, am4charts.XYChart3D);
      // let chart = am4core.create("stackedColumnChart3Ddiv", am4charts.XYChart3D);
      chart22.hiddenState.properties.opacity = 0; // this creates initial fade-in
      // And, for a good measure, let's add a legend
      // chart22.legend = new am4charts.Legend();

      chart22.colors.list = [
        am4core.color("#7ddc67"),
        am4core.color("#dc6967"),
      ];

      //Add Data      
      chart22.data = this.chartConfig;

      chart22.colors.step = 2;
      chart22.padding(30, 30, 10, 30);
      // chart.legend = new am4charts.Legend();

      let categoryAxis = chart22.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "day";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart22.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.minWidth = 50;

      // this.createSeriesPercentage3D(chart22, "normalPercentage", "Normal Percentage(%)", "day");
      // this.createSeriesPercentage3D(chart22, "highPercentage", "High Percentage(%)", "day");

      // if(this.chartConfig && this.chartConfig['isVisitorEntries'] == true){
      //   this.createSeriesPercentage3D(chart22, "normalPercentage", "Normal Percentage(%)", "day");
      //       this.createSeriesPercentage3D(chart22, "highPercentage", "High Percentage(%)", "day");
      // }else{
      //   this.createSeriesPercentage3D(chart22, "presentPercentage", "Present Percentage(%)", "day");
      //   this.createSeriesPercentage3D(chart22, "absentPercentage", "Absent Percentage(%)", "day");
      // }
      this.createSeriesPercentage3D(chart22, this.dataFromParentComp['fieldname1'], this.dataFromParentComp['displayfieldname1'], "day");
      this.createSeriesPercentage3D(chart22,this.dataFromParentComp['fieldname2'], this.dataFromParentComp['displayfieldname2'], "day");
      chartTypeClass = chart22;
    });
  }
  createSeriesPercentage3D(chart, field, name, category) {

    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.columns.template.width = am4core.percent(80);
    series.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";

    series.name = name;
    series.dataFields.categoryX = category;
    series.dataFields.valueY = field;

    series.dataFields.valueYShow = "totalPercent";
    series.dataItems.template.locations.categoryX = 0.5;
    series.stacked = true;
    series.tooltip.pointerOrientation = "vertical";

    let bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet.label.fill = am4core.color("#ffffff");
    bullet.locationY = 0.5;
  }

  /**
   * 
   * @param chartTypeClass 
   */
  pieChartFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {
      // Create chart instance
      let chart33 = am4core.create(chartId, am4charts.PieChart);
      // let chart = am4core.create(this.pieChartElement, am4charts.PieChart);
      // And, for a good measure, let's add a legend
      // chart33.legend = new am4charts.Legend();

      // Add data
      chart33.data = this.chartConfig;

      // Add and configure Series
      // let pieSeries = new am4charts.PieSeries();
      // chart.series.push(pieSeries);

      let pieSeries = chart33.series.push(new am4charts.PieSeries());
      pieSeries.config = this.chartSeriesConfig;

      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      // let created = async () => await chart33.exporting.getImage("png");
      // console.log(created); // contains exported image data
      // console.log(this.chart_img_div);

      //convert image to base64encode
      /*     var self = this;
          let imgData = chart33.exporting.getImage("png").then(function (imgData) {
            console.log(imgData); // contains exported image data
            let image = self.elementRef.nativeElement.querySelector('#' + self.chartImageID);
            image.innerHTML = '<img src="' + imgData + '">';
            // this.getImgData(imgData);
            // return imgData;
          }, err => {
            console.log('Fetch Error: ', err);
          }); */


      // this.chart_img_div.innerHTML = '<img src="' + imgData + '">';

      // console.log(this.chart_img_div);

      chartTypeClass = chart33;

      this.getChartImageBase64Data(chart33, "png");

    });
  }

  /**
   * 
   * @param chartTypeClass 
   */
  pieChartVariableRadiusFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {
      // Create chart instance
      let chart44 = am4core.create(chartId, am4charts.PieChart);
      // And, for a good measure, let's add a legend
      // chart44.legend = new am4charts.Legend();
      // Add data
      chart44.data = this.chartConfig;
      /**radius */
      chart44.hiddenState.properties.opacity = 0; // this creates initial fade-in
      /**radius */

      // Add and configure Series
      // let pieSeries = new am4charts.PieSeries();
      // chart.series.push(pieSeries);
      // pieSeries.dataFields = this.chartSeriesConfig.dataFields;
      // pieSeries.colors.list = this.chartSeriesConfig.colors.list;

      let pieSeries = chart44.series.push(new am4charts.PieSeries());
      pieSeries.config = this.chartSeriesConfig;
      /**Radius */
      pieSeries.dataFields.radiusValue = this.chartSeriesConfig.dataFields.value;
      pieSeries.slices.template.cornerRadius = 6;
      pieSeries.colors.step = 3;
      pieSeries.hiddenState.properties.endAngle = -90;
      /**Radius */
      // chart.series.push(pieSeries);
      chartTypeClass = chart44;
      this.getChartImageBase64Data(chart44, "png");
    });
  }

  /**
   * 
   * @param chartTypeClass 
   */
  pieChart3DFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {

      // Create chart instance
      let chart55 = am4core.create(chartId, am4charts.PieChart3D);
      chart55.hiddenState.properties.opacity = 0; // this creates initial fade-in
      // And, for a good measure, let's add a legend
      // chart55.legend = new am4charts.Legend();

      // Add data
      chart55.data = this.chartConfig;

      // Add and configure Series
      // let pieSeries = new am4charts.PieSeries3D();
      // chart.series.push(pieSeries);
      let pieSeries = chart55.series.push(new am4charts.PieSeries3D());
      pieSeries.config = this.chartSeriesConfig;

      chartTypeClass = chart55;
      this.getChartImageBase64Data(chart55, "png");
    });
  }

  /**
   * 
   * @param chartTypeClass 
   */
  pieChart3DVariableHeightFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {

      // Create chart instance
      let chart66 = am4core.create(chartId, am4charts.PieChart3D);
      // And, for a good measure, let's add a legend
      // chart66.legend = new am4charts.Legend();

      // Add data      
      chart66.data = this.chartConfig;

      /**variable height */
      chart66.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chart66.innerRadius = am4core.percent(40);
      chart66.depth = 120;
      /**variable height */

      // Add and configure Series
      // let pieSeries = new am4charts.PieSeries3D();
      // chart.series.push(pieSeries);

      let pieSeries = chart66.series.push(new am4charts.PieSeries3D());
      pieSeries.config = this.chartSeriesConfig;

      /**variable height */
      pieSeries.dataFields.depthValue = this.chartSeriesConfig.dataFields.value;
      pieSeries.slices.template.cornerRadius = 5;
      pieSeries.colors.step = 3;
      /**variable height */

      chartTypeClass = chart66;
      this.getChartImageBase64Data(chart66, "png");
    });
  }

  /**
   * 
   * @param chartTypeClass 
   */
  xyChartFn(chartTypeClass, chartId) {
    this.zone.runOutsideAngular(() => {

      let chart77 = am4core.create(chartId, am4charts.XYChart);

      chart77.paddingRight = 20;

      let data = [];
      let visits = 10;
      for (let i = 1; i < 366; i++) {
        visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
      }

      chart77.data = data;

      /* chart.colors.list = [
        am4core.color("#845EC2"),
        // am4core.color("#D65DB1"),
        // am4core.color("#FF6F91"),
        // am4core.color("#FF9671"),
        // am4core.color("#FFC75F"),
        am4core.color("#F9F871")
      ]; */

      let dateAxis = chart77.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart77.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart77.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";

      series.tooltipText = "{valueY.value}";
      chart77.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart77.scrollbarX = scrollbarX;

      chartTypeClass = chart77;
    });
  }

  /*************************************************************************************************************/
  /**                                               Chart Type Function Start Here                            **/
  /*************************************************************************************************************/


  /**
   * get base64 data from chart function and update in image tag
   * @param chart 
   * @param format 
   */
  getChartImageBase64Data(chart, format) {
    var self = this;
    self.chart_img_div = self.elementRef.nativeElement.querySelector('#' + this.chartImageID);
    //convert image to base64encode
    chart.exporting.getImage(format).then(function (imgData) {
      self.chart_img_div.innerHTML = '<img id="' + self.chartImageID + '-tag" src="' + imgData + '" >';
      // console.log(imgData); // contains exported image data
    });
  }

  /**
   * write common function for chart dispose
   * @param chartTypeClass 
   */
  chartDispose(chartTypeClass) {
    this.zone.runOutsideAngular(() => {
      if (chartTypeClass) {
        chartTypeClass.dispose();
      }
    });
  }

  /**
   * chart data dispose here
   */
  ngOnDestroy(): void {
    this.chartDispose(this.StackedColumnChart);
    this.chartDispose(this.StackedColumnChart3D);

    this.chartDispose(this.PieChart);
    this.chartDispose(this.PieChartVariableRadius);

    this.chartDispose(this.PieChart3D);
    this.chartDispose(this.PieChart3DVariableHeight);

    this.chartDispose(this.XYChart);
  }

  backup() {


    /****************************************************************** */
    /*  {
       "dataFields": {
         "value": "Count",
         "category": "Attendance"
       },
       "colors": {
         "list": [
           "#7ddc67",
           "#dc6967",
         ]
       },
       "events": [{
         "type": "hide",
         "callback": function (ev) {
           console.log("Oh noes! A PieSeries was hidden.");
         }
       }, {
         "type": "show",
         "callback": function (ev) {
           console.log("Ah, there it is. Phew...");
         }
       }],
       "adapter": [{
         "type": "tooltipText",
         "callback": function(val) {
           return "This is a PieSeries: " + val
         }
       }] */
    /*  "events": {
       "hide": function (ev) {
         console.log("Oh noes! A PieSeries was hidden.");
       },
       "show": function (ev) {
         console.log("Ah, there it is. Phew...");
       }
     } 
  };*/


    /* pieSeries.events.on("hidden", function (ev) {
      console.log("Oh noes! A PieSeries was hidden.");
    });

    pieSeries.events.on("shown", function (ev) {
      console.log("Ah, there it is. Phew...");
    }); */


    // Add and configure Series
    /* let series = new am4charts.PieSeries();
    // configure series
    let pieSeries = chart.series.push(series);

    let f = [{
      "type": "PieSeries",
      "dataFields": {
        "category": "country",
        "value": "visits"
      },
      "colors": {
        "list": [
          "#7ddc67",
          "#dc6967",
        ]
      }
    }];

    // let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.category = "Attendance";
    pieSeries.dataFields.value = "Count";
    pieSeries.colors.list = [
      am4core.color("#7ddc67"),
      am4core.color("#dc6967"),
    ];

    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
*/

  }

}