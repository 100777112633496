import { Component, OnInit, HostBinding, OnChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormDataService } from '../services/form-data.service';
import { FieldConfig } from "../interface/field.interface";
@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnChanges {

    /** only particular field data */
    field: FieldConfig;
    /** only particular field group data */
    group: FormGroup;
    onChange: Function;

    /**Dependent DropDown Function */
    // dependentDropDownOnChange: Function;
    onCallBackFn: Function;

    /** Total dynamic form "this" data */
    dynamicFormCompRef: any;

    /** total form data values */
    formData: object;
    /** only particular field value */
    fieldData: any;
    div_Partition_value: string;
    /** Div partition default value set here */
    divPartitionColumnsDefault: string = this.formDataService.getDivPartitionColumnsDefault();
    toSelectValue: any;

    onCallbackInField: Function;
    onCallBackInDynamicTag: Function;
    DropDownValueIsObject: boolean;
    /** Dynamic form sector using componment this value */
    curParentRef: any;

    /**
     * Adding Class & Id to selector tag here
     * Using HostBinding directive
     */
    /**
     * Reference urls list below 
     * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
     * https://github.com/angular/angular/issues/7289#issuecomment-390415535
     * https://alligator.io/angular/hostbinding-hostlistener/
     */
    /** Class set here */
    @HostBinding('class')
    get hostClasses(): string {
        return [
            'form-group',
            this.field.divPartitionColumns ? this.div_Partition_value + this.field.divPartitionColumns : this.divPartitionColumnsDefault,
            "sector-cls-" + this.field.name,
            (!this.field.hidden) ? 'fieldShow' : 'fieldHidden',
        ].join(' ');
    }

    /** Id set here */
    @HostBinding('attr.id')
    get idSet(): string {
        return "sector-id-" + this.field.name;
    }
    /************************* End Here ***************************/
    selected: Object = {};

    constructor(private formDataService: FormDataService) {
        this.div_Partition_value = formDataService.getDivPartitionColumns();
    }

    ngOnInit() {
        // console.log("---field------", this.field);
        // console.log("----fieldData-----", this.fieldData);
        this.DropDownValueIsObject = this.field.DropDownValueIsObject || false;
        this.onCallbackInField = this.field.onCallback;
        this.onCallBackInDynamicTag = this.onCallBackFn;

        /** 
         * Update Form Value binding login here
         * https://stackblitz.com/edit/how-to-set-default-value-of-mat-select-when-options-are-retriev 
         */
        if (this.DropDownValueIsObject) {
            const toSelect = this.field.options.find(c => c.label == this.fieldData);
            this.group.get(this.field.name).setValue(toSelect);
        } else {
            this.group.get(this.field.name).setValue(this.fieldData);
        }
    }

    ngOnChanges() { }

    /**
     * Show & hide function
     */
    excuteFormCondition(selectedValue: any) {

        let selectedVal = (this.DropDownValueIsObject) ? selectedValue.key : selectedValue;

        /** object for callback function */
        var dataObj = {
            selectedValue: selectedValue,
            options: this.field.options,
            fieldName: this.field.name,
            isObject: this.DropDownValueIsObject
        }
        // console.log('----select component data-----', dataObj);

        /** In Config Field applied callback function logic here */
        this.onCallbackInField && this.onCallbackInField(dataObj);

        /** In Dynamic form tag applied callback function logic here */
        this.onCallBackInDynamicTag && this.onCallBackInDynamicTag.call(this.curParentRef, dataObj);

        /** onChange function logic here */
        this.onChange && this.onChange.call(this.dynamicFormCompRef, this.field);
    }

    /**DependentDropDown */
    excuteFormDependentDropDown(selectedValue: any, dropDownValues: any, fieldName: string) {
        // console.log(selectedValue);
        // console.log(dropDownValues);
        // console.log(fieldName);
        // this.dependentDropDownOnChange && this.dependentDropDownOnChange.call(this.dynamicFormCompRef, selectedValue, dropDownValues, fieldName);
    }




    /*   Not use now just future reference purpose
    
    https://stackoverflow.com/questions/35945001/binding-select-element-to-object-in-angular 
    https://stackblitz.com/edit/angular-7kzu8w?file=app%2Fapp.component.ts
    https://github.com/ng-select/ng-select
    https://stackblitz.com/edit/ng-select?file=app%2Fapp.component.ts
    https://ng-select.github.io/ng-select#/forms
 
    https://stackoverflow.com/questions/45369344/angular-4-reactive-forms-select-item-in-a-list-from-object-not-referenced-in
    https://netbasal.com/understanding-the-comparefn-input-in-angular-v4-4a401ef4fc4c
    https://codeburst.io/angular-material-select-module-the-comparewith-function-9dfdb4035373
 
    https://malcoded.com/posts/angular-fundamentals-reactive-forms
        
    */
    compareFn(a, b) {
        //b = this.selectedLevelCustomCompare;
        console.log(a);
        console.log(b);
        //console.log(a, b, a && b && a.num == b.num);
        //return a && b && a.num == b.num; 
    }


}
