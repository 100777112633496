import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() {
  }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName), { bookType: 'xlsx', type: 'array' });
  }

  jsonToExcelSheet(XLConfig) {
    /* Worksheet Config data */
    var config = XLConfig;
    // console.log("config",config)
    /* add to workbook */
    var workbook = XLSX.utils.book_new();
    if (config.multiSheet === true) {
      let rowsData = Object.keys(config.rows);

      // console.log("rowsData",rowsData)
      rowsData && rowsData.forEach((item, index) => {
        // console.log("key",item)
        //   console.log("val",config.rows[item])
        /* generate a worksheet */
        var worksheet = XLSX.utils.json_to_sheet(config.rows[item]);
        /* append worksheet */
        XLSX.utils.book_append_sheet(workbook, worksheet, item);
      });
    } else {
      /* generate a worksheet */
      var worksheet = XLSX.utils.json_to_sheet(config.rows, { header: config.header, skipHeader: config.skipHeader });
      XLSX.utils.book_append_sheet(workbook, worksheet, "sheet");
    }
    /* write workbook and force a download */
    XLSX.writeFile(workbook, config.fileName);
  }

}