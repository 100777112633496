export class Constants {
    
    /** Breadcrumbs Home Link Data Here */
    public static readonly homeName: string = 'Home';
    public static readonly homeRouterLink: string = '/layout';
    /** Route Link */
    public static readonly login: string = '/login';
    public static readonly logout: string = '/logout';
    public static readonly dashboard: string = '/layout/dashboard';
    public static readonly student: string = '/layout/students';
    public static readonly attendance: string = '/layout/attendance';
    public static readonly configurationreports: string = '/layout/configurationreports';
    public static readonly changepassword: string = '/layout/changepassword';
    public static readonly employee: string = '/layout/employees';
    public static readonly employeeattendance: string = '/layout/employeesattendance';
    public static readonly visitorentries: string = '/layout/visitorentries';
    public static readonly employeePhotos: string = '/layout/employeePhotos';

    

}