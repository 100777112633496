import { Component,HostBinding, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FormDataService } from '../services/form-data.service';
import { FieldConfig } from "../interface/field.interface";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  field: FieldConfig;
  /** only particular field group data */
  group: FormGroup;

  onChange: Function;

  /** Total dynamic form "this" data */
  dynamicFormCompRef: any;

  /** total form data values */
  formData: object;
  /** only particular field value */
  fieldData: any;
  onClick: Function
  div_Partition_value:string;
  
  /** Div partition default value set here */
  divPartitionColumnsDefault: string = this.formDataService.getDivPartitionColumnsDefault();

  /**
   * Adding Class & Id to selector tag here
   * Using HostBinding directive
   */
  /**
   * Reference urls list below 
   * https://stackoverflow.com/questions/35996212/is-it-possible-to-add-a-dynamic-class-to-host-in-angular-2
   * https://github.com/angular/angular/issues/7289#issuecomment-390415535
   * https://alligator.io/angular/hostbinding-hostlistener/
   */
  /** Class set here */
  @HostBinding('class')
  get hostClasses(): string {
    return [
      'form-group',
      this.field.divPartitionColumns ?  this.div_Partition_value + this.field.divPartitionColumns : this.divPartitionColumnsDefault,
      "sector-cls-" + this.field.name,
      (!this.field.hidden) ? 'fieldShow' : 'fieldHidden',
    ].join(' ');
  }

  /** Id set here */
  @HostBinding('attr.id')
  get idSet(): string {
    return "sector-id-" + this.field.name;
  }
  /************************* End Here ***************************/

  constructor(private  formDataService:FormDataService) { 
   this.div_Partition_value= formDataService.getDivPartitionColumns();
  }

 

  /**
   * Show & hide field function
   */
  excuteFormCondition() {
    this.onChange && this.onChange.call(this.dynamicFormCompRef, this.field);
  }

  ngOnInit() {
  }

}
