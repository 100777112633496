export class EmployeeAttendance {
    _id: string;
    OrganizationId: number;
    BranchId: number;
    ZoneId: number;
    IsActive: boolean;
    Month: number;
    EmployeeId: number;
    Year: number;
    Attendance: eAttendance;
    RollNo: string;
    EmployeeName: string;
}

export class eAttendance {
    Date: string;
    IsPresent: boolean;
    IsLeaveApplied: boolean;
    IsTransportBoarded: string;
    Time: boolean;
    Period: string;
}

export interface eAttendanceReportFilter {
    /** Date Wise, Month Wise */
    reportType: string;

    /** Date Wise Parameters */
    fromDate: string;
    toDate: string;

    /** Month Wise Parameters */
    month: string;
}