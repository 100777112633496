import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorEntriesService {

  public filtered_Data;
  public savedFilteredData_set: any[] = ['MayDate', 'MayMonth'];
  public tempSavedFilter;
  /**
   * 
   * @param apiService 
   */
  constructor(private apiService: ApiService) { }

/**
   * @param requestParams
   */
 getEndPoints(requestParams: object) {
  /**API Method Name */
  let apiMethod = 'getEndPoints';
  /**API Body Data */
  let apiBody = requestParams;
  /** API - POST SERVICE REQUEST */
  return this.apiService.post(apiMethod, apiBody);
}
/**
   * @param requestParams
   */
 getEntryStatsReport(requestParams: object) {
  /**API Method Name */
  let apiMethod = 'getEntryStatsReport';
  /**API Body Data */
  let apiBody = requestParams;
  /** API - POST SERVICE REQUEST */
  return this.apiService.post(apiMethod, apiBody);
}

/**
   * @param requestParams
   */
 getImageById(requestParams: object) {
  /**API Method Name */
  let apiMethod = 'getImageById';
  /**API Body Data */
  let apiBody = requestParams;
  /** API - POST SERVICE REQUEST */
  return this.apiService.post(apiMethod, apiBody);
}
 

  public attendanceResponse_data;
  setAttendance_res(attendanceResponse_data) {
    this.attendanceResponse_data = attendanceResponse_data;
  }
  getAttendance_res() {
    return this.attendanceResponse_data
  }

  setFiteredData(filtered_Data) {
    this.filtered_Data = filtered_Data;
    if (filtered_Data !== null) {
      let filtersDb = Object.keys(this.filtered_Data);
      filtersDb.forEach(filtKey => {
        if (filtKey === 'savedFilterName') {
          console.log(filtKey);
          this.tempSavedFilter = this.filtered_Data[filtKey];
          console.log(this.tempSavedFilter);

          var index = this.savedFilteredData_set.indexOf(this.tempSavedFilter);
          console.log(index)
          var rank = index + 1;
          console.log(rank)
          if (rank === 0) {
            this.savedFilteredData_set.push(this.filtered_Data[filtKey]);
            console.log(this.savedFilteredData_set);
          }
          else{
            alert("Filter Name is already exist");
          }
        }
      });
    }
  }

  getFiteredData() {
    return this.filtered_Data
  }

}
