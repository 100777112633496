import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, Form } from "@angular/forms";

// import { formattedError } from '@angular/compiler';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { FormDataService } from '../services';
import { FieldConfig } from "../interface";
import { DatePipe } from '@angular/common';


@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [DatePipe/* , BsModalService, BsModalRef */]
})

export class DynamicFormComponent implements OnInit {

  @Input() fields: FieldConfig[] = [];
  @Output() submittedForm: EventEmitter<any> = new EventEmitter();

  /** Dynamic form sector using componment this value */
  @Input() curParentRef: any;
  @Input() onCallBackFn: Function;

  @Input() onFormSubmit: Function;
  @Input() onFormError: Function;

  /**
   * output data for blinding and update fields (instead of services)
   */
  @Input() formDataValues: FormData;

  public outPutArr: any = {}
  /**
   * Form Related property here
   */
  public form: FormGroup;
  public submitted = false;
  /**
   * Buttons related property here
   */
  public navBtnData: any;
  // public tabsArray: string[] = ['personal', 'parents', 'health'];

  public tabsArray: string[] = ['student', 'parents', 'guardian', 'declaration', 'document', 'admission'];
  public tabsArray_old: string[] = ['student', 'parents', 'guardian'];

  public tabsArraylength = this.tabsArray.length;
  /**
   * Get Current url path here
   */
  public currentUrl = this.router.url;
  public currentUrlName = this.currentUrl.replace('/form-layout/', '');
  public calculatedAge: any;

  // modalRef: BsModalRef;
  /******************************************************************************
   * Onload function
   * @param fb
   * @param formDataService 
   * @param location 
   * @param router 
   * @param route 
   *****************************************************************************/
  constructor(private fb: FormBuilder, private formDataService: FormDataService, location: Location,
    private router: Router, private route: ActivatedRoute, private datepipe: DatePipe, ) {
    // console.log(this.currentUrlName);
  }

  /******************************************************************************
   * NgOnInit function
   *****************************************************************************/
  ngOnInit() {
    var self = this;
    /** Form control create here */
    this.form = this.createControl();
    /** Form submit button create here */
    this.navBtnData = this.navBtns();
    /** Form data call from service through and assign a variable */
    let outputData = this.formDataService.getOutputData(this.navBtnData);

    if (this.currentUrlName == this.navBtnData.current) {

      if (outputData[this.currentUrlName] != '') {
        /** get foram data here */
        this.formDataValues = outputData[this.currentUrlName];
      }
    }
    /** set formdata function */
    this.patchValuesAllFormFields(this.fields, this.form, this.formDataValues);
    /** Show & hide fields function */
    this.onFieldValueChanged(this.fields);

    this.onFieldLengthAdded(this.fields);

  }
  /*****************************************************************************
   * Form Create & related functions are start here
   *****************************************************************************/
  /**
   * Form create function
   **/
  createControl() {

    let formDataArr: any;
    const group = this.fb.group({});
    let fieldsArr = {};
    // let matchesArr = {};
    this.fields.forEach(field => {
      if (field.type === "button") return;

      if (field.type === "file") {
        fieldsArr[field.name] = this.fb.control([], this.bindValidations(field.validations || []));
      } else if (field.type === "documentFile") {
        fieldsArr[field.name] = this.fb.control([], this.bindValidations(field.validations || []));
      } else if (field.type == 'subgroup') {
        let opts = {};
        for (let opt of field.sg_fields) {
          if (opt.type === "checkboxgroup" && opt.options) {
            /** sub group validation require or not check here */
            opts[opt.name] = this.fb.array([], this.bindValidations(opt.validations || []));
          } else {
            /** sub group validation require or not check here */
            opts[opt.name] = this.fb.control(opt.value || '', this.bindValidations(opt.validations || []));
          }
          // opts[opt.name] = this.fb.control(opt.value || '', this.bindValidations(field.validations || []));
        }
        fieldsArr[field.name] = this.fb.group(opts);
      } else if (field.type === 'table') {
        fieldsArr[field.name] = this.fb.array([
          this.initTable(field.col_fields, field.col_length, field)
        ])
      } else if (field.type === "checkboxgroup" && field.options) {
        fieldsArr[field.name] = this.fb.array([], this.bindValidations(field.validations || []));
      } else {
        fieldsArr[field.name] = this.fb.control(field.value || '', this.bindValidations(field.validations || []));
      }
      formDataArr = this.fb.group(fieldsArr);
    });
    return formDataArr;
  }

  /**
   * Table create function
   * @param fieldArr 
   * @param fieldLen 
   * @param field 
   */
  initTable(fieldArr, fieldLen, field) {
    let res;
    let tdRow = {};
    for (let tdCol of fieldArr) {
      tdRow[tdCol.name] = this.fb.control(tdCol.value || '', this.bindValidations(tdCol.validations || []));
    }
    res = this.fb.group(tdRow)
    return res;
  }

  /**
   * Validations
   * @param validations 
   */
  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  /**
   * Buttons creation function
   */
  navBtns() {
    let navBtnArr = {};
    navBtnArr['previous'] = "";
    navBtnArr['current'] = "";
    navBtnArr['next'] = "";
    navBtnArr['finish'] = "";
    let j: any;
    for (j in this.tabsArray) {
      j = parseInt(j);
      let previous: number = (j - 1);
      let next: number = parseInt(j) + 1;
      if (this.tabsArray[j] === this.currentUrlName && j === 0) {
        navBtnArr['current'] = this.tabsArray[j];
        navBtnArr['next'] = this.tabsArray[next];
      } else if (this.tabsArray[j] === this.currentUrlName && (j > 0 && j < this.tabsArraylength - 1)) {
        navBtnArr['current'] = this.tabsArray[j];
        navBtnArr['previous'] = this.tabsArray[previous];
        navBtnArr['next'] = this.tabsArray[j + 1];
      } else if (this.tabsArray[j] === this.currentUrlName && j === this.tabsArraylength - 1) {
        navBtnArr['current'] = this.tabsArray[j];
        navBtnArr['previous'] = this.tabsArray[previous];
        navBtnArr['finish'] = 'success';
      }
    }
    return navBtnArr;
  }

  /**
   * @param value 
   */
  isArrayOfString(value: any): boolean {
    return Array.isArray(value) && !!value.length && value.every(item => typeof item === "string")
  }

  /**
   * Lets' Convert 1 '1' and 'true' to true and 0 '0' 'false' null and undefined to false
   * url: https://bloomlab.blogspot.com/2018/06/typescript-recipe-elegant-parse-boolean.html 
   * @param value 
   */
  public primitiveToBoolean(value?: string | number | boolean | null): boolean {
    if (value == null) {
      return false;
    }
    if (value === 'true') {
      return true;
    }
    return typeof value === 'string'
      ? !!+value   // we parse string to number first
      : !!value;
  }

  /**
    * patch values set function and this function calling in ngOnInit() function
    * @param formGroup 
    * @param formValue 
    */
  patchValuesAllFormFields(fields: FieldConfig[], formGroup: any, formValue: FormData) {
    let mn = 0;
    // console.log(formValue);
    // console.log(fields);
    formValue && Object.keys(formValue).forEach(fieldName => {

      if (!this.primitiveToBoolean(formGroup.controls[fieldName])) {
        mn = mn - 1;
      }
      if (this.primitiveToBoolean(formGroup.controls[fieldName])) {

        // if (fields[mn]['type'] === "select") {
        // this.onDependentDropDownValueChanged(formValue[fieldName], fields[mn]['options'], fields[mn]['name']);
        // }

        if (typeof formValue[fieldName] == "object" && Array.isArray(formValue[fieldName])) {
          if (this.isArrayOfString(formValue[fieldName])) {
            formGroup.setControl(fieldName, this.fb.array(formValue[fieldName] || [], this.bindValidations(fields[mn].validations || [])));
          } else {
            /* sub loop start here */
            formValue[fieldName] && Object.keys(formValue[fieldName]).forEach(fieldInner => {
              if (this.primitiveToBoolean(formGroup.controls[fieldName].controls[fieldInner])) {
                if (typeof formValue[fieldName][fieldInner] == "object" && Array.isArray(formValue[fieldName][fieldInner])) {
                  formGroup.controls[fieldName].setControl(fieldInner, this.fb.array(formValue[fieldName][fieldInner] || []));

                } else if (typeof formValue[fieldName][fieldInner] == "object" && !Array.isArray(formValue[fieldName][fieldInner])) {

                  if (Object.keys(formValue[fieldName][fieldInner]).length === 0) {
                    formGroup.controls[fieldName].controls[fieldInner].patchValue(formValue[fieldName][fieldInner]);
                  } else if (formValue[fieldName][fieldInner].hasOwnProperty('filename') && formValue[fieldName][fieldInner].hasOwnProperty('filetype') && Object.keys(formValue[fieldName][fieldInner]).length === 4) {
                    formGroup.controls[fieldName].controls[fieldInner].patchValue(formValue[fieldName][fieldInner]);
                  } else {
                    formGroup.controls[fieldName].setControl(fieldInner, this.fb.group(formValue[fieldName][fieldInner] || {}));
                  }
                  // formGroup.controls[fieldName].setControl(fieldInner, this.fb.group(formValue[fieldName][fieldInner] || {}));
                } else {

                  formGroup.controls[fieldName].controls[fieldInner].patchValue(formValue[fieldName][fieldInner] || '');
                }
                formGroup.get(fieldName).get(fieldInner).updateValueAndValidity();
              }
            });
            /* sub loop end here */
          }
        } else if (typeof formValue[fieldName] == "object" && !Array.isArray(formValue[fieldName])) {
          if (Object.keys(formValue[fieldName]).length === 0) {
            formGroup.controls[fieldName].patchValue(formValue[fieldName]);
          } else if (formValue[fieldName].hasOwnProperty('filename') && formValue[fieldName].hasOwnProperty('filetype') && Object.keys(formValue[fieldName]).length === 4) {
            formGroup.controls[fieldName].patchValue(formValue[fieldName]);
          } else {
            /* sub loop start here */
            formValue[fieldName] && Object.keys(formValue[fieldName]).forEach(fieldInner => {
              if (this.primitiveToBoolean(formGroup.controls[fieldName].controls[fieldInner])) {
                if (typeof formValue[fieldName][fieldInner] == "object" && Array.isArray(formValue[fieldName][fieldInner])) {
                  formGroup.controls[fieldName].setControl(fieldInner, this.fb.array(formValue[fieldName][fieldInner] || []));

                } else if (typeof formValue[fieldName][fieldInner] == "object" && !Array.isArray(formValue[fieldName][fieldInner])) {

                  if (Object.keys(formValue[fieldName][fieldInner]).length === 0) {
                    formGroup.controls[fieldName].controls[fieldInner].patchValue(formValue[fieldName][fieldInner]);
                  } else if (formValue[fieldName][fieldInner].hasOwnProperty('filename') && formValue[fieldName][fieldInner].hasOwnProperty('filetype') && Object.keys(formValue[fieldName][fieldInner]).length === 4) {
                    formGroup.controls[fieldName].controls[fieldInner].patchValue(formValue[fieldName][fieldInner]);
                  } else {
                    formGroup.controls[fieldName].setControl(fieldInner, this.fb.group(formValue[fieldName][fieldInner] || {}));
                  }

                } else {
                  formGroup.controls[fieldName].controls[fieldInner].patchValue(formValue[fieldName][fieldInner]);
                }

                formGroup.get(fieldName).get(fieldInner).updateValueAndValidity();
              }
            });
            /**sub loop end here **/
          }
        } else {
          // console.log(formValue[fieldName],":", fieldName)
          // fields.forEach(field => {
          //   if (field.host_cascading && field.options) {
          //     // this.changeFieldOptions(formValue[fieldName], field.options);

          //     formGroup.controls[fieldName].patchValue(formValue[fieldName]);
          //   }
          //   else {
          //     formGroup.controls[fieldName].patchValue(formValue[fieldName]);
          //   }

          // })


          formGroup.controls[fieldName].patchValue(formValue[fieldName]);

        }
        formGroup.get(fieldName).updateValueAndValidity();
        this.setValidatorsOfField(formGroup, fields[mn]);
      }
      mn++;
    });
    formGroup.updateValueAndValidity();
  }

  /**
   * Set Validation of field
   * @param formGroup 
   * @param subFieldsArr 
   */
  setValidatorsOfField(formGroup, subFieldsArr = {}) {

    var fieldType = subFieldsArr['type'];
    var fieldName = subFieldsArr['name'];
    var fieldValidations = subFieldsArr['validations'];

    //  if (formGroup.get(fieldName)) {
    if (fieldType === "subgroup" && subFieldsArr['sg_fields'].length > 0) {
      formGroup.get(fieldName) && subFieldsArr['sg_fields'].forEach(subGroupField => {
        formGroup.get(fieldName).get(subGroupField.name) && formGroup.get(fieldName).get(subGroupField.name).setValidators(this.bindValidations(subGroupField.validations || []));
        formGroup.get(fieldName).get(subGroupField.name) && formGroup.get(fieldName).get(subGroupField.name).updateValueAndValidity();
      });
    } else if (fieldType === "table" && subFieldsArr['col_fields'].length > 0) {
      formGroup.get(fieldName) && formGroup.get(fieldName).controls.forEach(tableCtrl => {
        subFieldsArr['col_fields'].forEach(tableField => {
          tableCtrl.get(tableField.name) && tableCtrl.get(tableField.name).setValidators(this.bindValidations(tableField.validations || []));
          tableCtrl.get(tableField.name) && tableCtrl.get(tableField.name).updateValueAndValidity();
        });
      });
    } else {
      formGroup.get(fieldName) && formGroup.get(fieldName).setValidators(this.bindValidations(fieldValidations || []));
      formGroup.get(fieldName) && formGroup.get(fieldName).updateValueAndValidity();
    }
    // }
  }

  /**
   * Clear validation of field
   * @param formGroup 
   * @param subFieldsArr 
   */
  clearValidatorsOfField(formGroup, subFieldsArr = {}) {

    var fieldType = subFieldsArr['type'];
    var fieldName = subFieldsArr['name'];

    //if (formGroup.get(fieldName)) {
    if (fieldType === "subgroup" && subFieldsArr['sg_fields'].length > 0) {
      formGroup.get(fieldName) && subFieldsArr['sg_fields'].forEach(subGroupField => {
        formGroup.get(fieldName).get(subGroupField.name) && formGroup.get(fieldName).get(subGroupField.name).clearValidators();
        formGroup.get(fieldName).get(subGroupField.name) && formGroup.get(fieldName).get(subGroupField.name).updateValueAndValidity();
      });
    } else if (fieldType === "table" && subFieldsArr['col_fields'].length > 0) {
      formGroup.get(fieldName) && formGroup.get(fieldName).controls.forEach(tableCtrl => {
        subFieldsArr['col_fields'].forEach(tableField => {
          tableCtrl.get(tableField.name) && tableCtrl.get(tableField.name).clearValidators();
          tableCtrl.get(tableField.name) && tableCtrl.get(tableField.name).updateValueAndValidity();
        });
      });
    } else {
      formGroup.get(fieldName) && formGroup.get(fieldName).clearValidators();
      formGroup.get(fieldName) && formGroup.get(fieldName).updateValueAndValidity();
    }
    //}

  }

  /**
   * Show & hide fields function
   * @param field 
   */
  onFieldValueChanged(field) {
    let formData = this.form.value;
    var self = this;
    function enableDisableFields(fields, thisForm) {
      fields.forEach(field => {
        let result = true;
        let subGroup_result = true;
        if (!field.enableIf && field.type === "subgroup") {
          enableDisableFields(field.sg_fields, thisForm.get(field.name));
        } else {
          field.enableIf && field.enableIf.forEach(subField => {
            subField && subField.conditions && subField.conditions.forEach(condition => {
              let mainData = formData, fieldName = subField.field;
              let innerObjs = subField.field.split(".");
              if (innerObjs.length > 1) {
                mainData = formData[innerObjs[0]];
                fieldName = innerObjs[1];
              }
              switch (condition.operand) {
                case "equals":
                  if (Array.isArray(mainData[fieldName])) {
                    result = result && mainData[fieldName].includes(condition.value);
                    self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, field.sg_fields, field.col_fields);
                  } else {
                    if (!field.sg_fields && !field.col_fields) {
                      result = result && (mainData[fieldName] === condition.value);
                      field.validations && self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, '', field.col_fields);
                    } else if (field.sg_fields) {
                      result = result && (mainData[fieldName] === condition.value);
                      self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, field.sg_fields, field.col_fields);
                      enableDisableFields(field.sg_fields, thisForm.get(field.name));
                    } else if (field.col_fields) {
                      result = result && (mainData[fieldName] === condition.value);
                      self.enableIfValidations(result, '', thisForm.get(field.name), field.type, field.sg_fields, field.col_fields, thisForm.get(field.name).controls);
                    }
                  }
                  break;
                case "contains":
                  if (!field.sg_fields) {
                    result = result && condition.value && condition.value.includes && condition.value.includes(mainData[fieldName]);

                    field.validations && self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, '', field.col_fields);
                  } else if (field.sg_fields) {
                    result = result && condition.value && condition.value.includes && condition.value.includes(mainData[fieldName]);

                    self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, field.sg_fields, field.col_fields);
                    enableDisableFields(field.sg_fields, thisForm.get(field.name));
                  }
                  break;
                case "not equals":
                  if (!field.sg_fields) {
                    result = result && (mainData[fieldName] !== condition.value);

                    field.validations && self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, '', field.col_fields);
                  } else if (field.sg_fields) {
                    result = result && (mainData[fieldName] !== condition.value);

                    self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, field.sg_fields, field.col_fields);
                    enableDisableFields(field.sg_fields, thisForm.get(field.name));
                  }
                  break;
                case "greater than":
                  if (!field.sg_fields) {
                    result = result && (parseInt(mainData[fieldName]) > condition.value);

                    field.validations && self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, '', field.col_fields);
                  } else if (field.sg_fields) {
                    result = result && (parseInt(mainData[fieldName]) > condition.value);

                    self.enableIfValidations(result, field.validations, thisForm.get(field.name), field.type, field.sg_fields, field.col_fields);
                    enableDisableFields(field.sg_fields, thisForm.get(field.name));
                  }
                  break;
              }
            })
          });
          field.hidden = !result;
        }

      });
    }
    enableDisableFields(this.fields, this.form);

    self.formDataService.setIndividualFormValue(self.form.value);
    let outputData33 = self.formDataService.getIndividualFormValue();
    // self.changeFieldOptions(outputData33.ClassId, field.options);
  }

  /**
   * 
   * @param result 
   * @param field_validations 
   * @param field_name 
   * @param field_type 
   * @param sg_fields 
   * @param column_fields 
   * @param thisForm_field_cntrl_name 
   */
  enableIfValidations(result, field_validations, field_name, field_type, sg_fields, column_fields, thisForm_field_cntrl_name: any = '') {
    if (field_type === "subgroup") {
      sg_fields.forEach(subgroups => {
        if (result && subgroups.validations) {
          field_name.get(subgroups.name).setValidators(this.bindValidations(subgroups.validations || []));
          field_name.get(subgroups.name).updateValueAndValidity();
        }
        else if (subgroups.validations && field_name.get(subgroups.name)) {
          field_name.get(subgroups.name).clearValidators();
          field_name.get(subgroups.name).updateValueAndValidity();
        }
      });
    } else if (field_type === "table") {
      thisForm_field_cntrl_name.forEach(table_cntrl => {
        column_fields.forEach(column_field => {
          if (result && column_field.validations) {
            table_cntrl.get(column_field.name).setValidators(this.bindValidations(column_field.validations || []));
            table_cntrl.get(column_field.name).updateValueAndValidity();
          }
          else if (column_field.validations && table_cntrl.get(column_field.name)) {
            table_cntrl.get(column_field.name).clearValidators();
            table_cntrl.get(column_field.name).updateValueAndValidity();
          }
        })
      });
    } else {
      if (result && field_validations) {
        field_name.setValidators(this.bindValidations(field_validations || []));
        field_name.updateValueAndValidity();
      }
      else if (field_name && field_validations) {
        field_name.clearValidators();
        field_name.updateValueAndValidity();
      }
    }
  }


  /**
    * Add Max and Min length to fields function
    * @param field 
    */
  onFieldLengthAdded(fields) {
    let result = {};
    fields.forEach(field => {
      if (field.type === "subgroup" && !field.validations) {
        this.onFieldLengthAdded(field.sg_fields);
      } else if (field.type === "table" && !field.validations) {
        this.onFieldLengthAdded(field.col_fields);
      } else {
        field.validations && field.validations.forEach(validationFields => {
          if (validationFields.length) {
            field[validationFields.name] = validationFields.length;
          }
        });
      }
    });
  }
  /*****************************************************************************
   * Form Create & related functions are end here
   *****************************************************************************/

  /****************************************************************************
   * After Submit functions are start here
   *****************************************************************************/
  /**
   * Validation checking in after submit/save buttons
   * @param formGroup 
   */
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        control.markAsTouched({ onlySelf: true });
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.markAsTouched({ onlySelf: true });
        this.validateAllFormFields(control);
      }
    });
  }



  /**
   * DependentDropDown function
   * @param selectedValue 
   * @param dropDownValues 
   * @param fieldName 
   */
  // onDependentDropDownValueChanged(selectedValue: any, dropDownValues: any, fieldName: string) {
  //   var self = this;
  //   var dataObj = {
  //     selectedValue: selectedValue,
  //     dropDownValues: dropDownValues,
  //     fieldName: fieldName
  //   }
  //   this.onCallBackFn && this.onCallBackFn.call(this.curParentRef, dataObj);
  // }
  /**
    * DependentDropDown function
    * @param selectedValue 
    * @param dropDownValues 
    * @param fieldName 
    */
  onDependentDropDownValueChanged_old(selectedValue: any, dropDownValues: any, fieldName: string) {
    var self = this;

    /** config Field data */
    let fieldsData = self.fields;
    fieldsData && fieldsData.forEach(mainField => {

      /** dependentDropDownIf field check and loop here */
      if (mainField.dependentDropDownIf) {
        let mainFieldName = "";
        mainField.dependentDropDownIf.forEach(subField => {

          /** other loop here */
          subField && subField.conditions && subField.conditions.forEach(condition => {
            /** variables set here */
            let child_options = condition.options;
            let keyValue = condition.value;

            let callbackFn = condition.callbackFn || function () { };

            mainFieldName = subField.field;

            /** onchange param(fieldName) and config Field Name same or not check here*/
            if (fieldName === mainFieldName) {
              switch (condition.operand) {

                /** Find: Type 1 Object data checking here */
                case "find":
                  self.form.get(mainField.name).setValue("");
                  // console.log('----selectedValue----', selectedValue);
                  // console.log('----child_options----', child_options);
                  if (selectedValue != '' && child_options != '') {
                    mainField.options = dropDownValues.find(element => element[keyValue] == selectedValue)[child_options];
                    console.log('----mainField.options----', mainField.options);
                  } else {
                    mainField.options = [];
                  }
                  // console.log('----mainField.options----', mainField.options);

                  break;

                /** Find: Type 2 Object data checking here */
                case "filter":
                  self.form.get(mainField.name).setValue("");
                  if (selectedValue != '' && child_options != '') {
                    // console.log('----selectedValue----', selectedValue);
                    // console.log('----child_options----', child_options);
                    mainField.options = child_options.filter((element) => element[keyValue] == selectedValue);
                  } else {
                    mainField.options = [];
                  }
                  // console.log('----mainField.options----', mainField.options);
                  break;

                /** Find: Type 3 Object data checking here */
                case "callback":
                  self.form.get(mainField.name).setValue("");
                  if (selectedValue != '' && child_options != '') {
                    let val = callbackFn(selectedValue);
                    // console.log(val);
                    mainField.options = child_options;
                    // console.log(child_options);
                  } else {
                    mainField.options = [];
                  }
                  break;
              }
            }
          })
        });
      }
    });
  }

  /**
   *  Finish button event function
   * @param event 
   * @param navBtnData 
   */
  successShowModal = false;
  onSubmit(event: Event, navBtnData, template) {
    this.submitted = true;

    event.preventDefault();
    event.stopPropagation();

    if (this.form.valid) {
      this.submittedForm.emit(this.form.value);
      this.onFormSubmit(this.form.value);

      this.formDataService.setOutPutData(this.form.value, navBtnData);
      this.formDataService.setInPutData(this.fields, navBtnData);
      // alert('Form submitted successfully..');
      this.successShowModal = true;
    } else {
      this.onFormError(this.form);
      this.validateAllFormFields(this.form);
    }
  }

  openModal(template) {
    // this.modalRef = this.modalService.show(template);
  }

  /**
   * Save & Next button event function
   * @param event 
   * @param navBtnData 
   */
  onSaveNext(event: Event, navBtnData) {
    this.submitted = true;
    event.preventDefault();
    event.stopPropagation();

    if (this.form.valid) {
      this.submittedForm.emit(this.form.value);
      this.onFormSubmit(this.form.value);

      // console.log("----------------json------------------------");
      // var jsonData = JSON.stringify(this.form.value);
      // console.log(jsonData);

      let redirectpath = navBtnData.next;
      this.formDataService.setOutPutData(this.form.value, navBtnData);
      this.formDataService.setInPutData(this.fields, navBtnData);

      this.router.navigateByUrl('/form-layout/' + redirectpath);

    } else {
      this.validateAllFormFields(this.form);

      // this.onFormError("");
    }
  }

  /**
   * Previous button event function
   * @param event 
   * @param navBtnData 
   */
  onPrevious(event: Event, navBtnData) {
    let redirectpath = navBtnData.previous;
    this.formDataService.setOutPutData(this.form.value, navBtnData);
    this.formDataService.setInPutData(this.fields, navBtnData);
    this.router.navigateByUrl('/form-layout/' + redirectpath);
  }

  /*****************************************************************************    
   * After Submit functions are ends here  
   *****************************************************************************/

  sections: Array<any>;
  changeFieldOptions(selectValue, fieldOptions) {
    // console.log(selectValue, ":", fieldOptions)
    if (selectValue && fieldOptions) {
      this.fields.forEach(field => {
        fieldOptions && fieldOptions.forEach(options => {
          if (selectValue === options.key) {
            this.hiddenFieldData(options.label);
            this.sections = options.Sections
            field.cascading_DropDown && field.cascading_DropDown.forEach(sec => {
              field.options = this.sections;
              console.log(field.options)
            })
          }
        })
      })
    }

    // this.cities = this.countryList.find(con => con.name == count).cities;
  }

  hiddenFieldData(labelValuue) {
    // console.log(labelValuue);
    let formData = this.form.value;
    // console.log(formData);
    let form_Group = this.form
    this.fields.forEach(field => {
      field.hiddenFieldData && field.hiddenFieldData.forEach(hiddenField => {
        if (formData[hiddenField.field]) {
          form_Group.controls[field.name].patchValue(labelValuue);
        }
      })
    });
  }

  previousFieldData() {
    let formData = this.form.value;
    let form_Group = this.form
    this.fields.forEach(field => {
      field.preFieldData && field.preFieldData.forEach(preField => {
        let preField_data = formData[preField.field];
        let previousValue = this.ageCalculate(preField_data, field.age_in_y_m_d);
        form_Group.controls[field.name].patchValue(previousValue);
      })
      field.sg_fields && field.sg_fields.forEach(subfields => {
        subfields.preFieldData && subfields.preFieldData.forEach(preSubFields => {
          let preField_data = formData[field.name][preSubFields.field];
          let previousValue = this.ageCalculate(preField_data, subfields.age_in_y_m_d);
          form_Group.get(field.name).get(subfields.name).patchValue(previousValue);
        })
      })
    });
  }

  ageCalculate(value, accrt_Age): any {
    if (value) {
      let latest_date = this.datepipe.transform(value, 'yyyy-MM-dd');
      var birthDate = latest_date;
      var d = new Date(birthDate);
      var mdate = birthDate.toString();
      var yearThen = parseInt(mdate.substring(0, 4), 10);
      var monthThen = parseInt(mdate.substring(5, 7), 10);
      var dayThen = parseInt(mdate.substring(8, 10), 10);
      var today = new Date();
      var birthday = new Date(yearThen, monthThen - 1, dayThen);
      var differenceInMilisecond = today.valueOf() - birthday.valueOf();
      var year_age = Math.floor(differenceInMilisecond / 31536000000);
      var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);

      if ((today.getMonth() == birthday.getMonth()) && (today.getDate() == birthday.getDate())) {
        alert("Happy B'day!!!");
      }
      var month_age = Math.floor(day_age / 30);
      day_age = day_age % 30;
      /* age in pure months and age in pure days*/
      // var totalMonths= (month_age + (year_age*12));
      // var totalDays =(tMnt*30) + day_age;
      if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)) {
        alert("not a number");
      }
      else {
        if (accrt_Age) {
          return this.calculatedAge = year_age + " years " + month_age + " months " + day_age + " days";
        }
        else {
          return this.calculatedAge = year_age;
        }

      }
    }
  }

  /**
   * All validation set of form
   * @param fields 
   * @param formGroup 
   * @param formData 
   */
  setValidatorsOfAllFields(fields, formGroup, formData) {
    fields.forEach(field => {
      if (formGroup.get(field.name)) {
        if (field.type === "subgroup") {
          this.setValidatorsOfAllFields(field.sg_fields, formGroup.get(field.name), formData);
        } else if (field.type === "table") {
          formGroup.get(field.name).controls.forEach(tableCtrl => {
            this.setValidatorsOfAllFields(field.col_fields, tableCtrl, formData);
          });
        } else {
          formGroup.get(field.name).setValidators(this.bindValidations(field.validations || []));
          formGroup.get(field.name).updateValueAndValidity();
        }
      }
    });
  }

  /**
   * All validation clear of form
   * @param fields 
   * @param formGroup 
   * @param formData 
   */
  clearValidatorsOfAllFields(fields, formGroup, formData) {
    fields.forEach(field => {
      if (formGroup.get(field.name)) {
        if (field.type === "subgroup") {
          this.setValidatorsOfAllFields(field.sg_fields, formGroup.get(field.name), formData);
        } else if (field.type === "table") {
          formGroup.get(field.name).controls.forEach(tableCtrl => {
            this.setValidatorsOfAllFields(field.col_fields, tableCtrl, formData);
          });
        } else {
          formGroup.get(field.name).clearValidators();
          formGroup.get(field.name).updateValueAndValidity();
        }
      }
    });
  }

  /****************************************************************************
  * Dynamic Form Function End here
  *****************************************************************************/

  /**
   * Set form values here
   * @param outputData 
   */
  setFormFieldValues(outputData) {
    this.form.patchValue(outputData);
  }

  /**
   * set form output values here 
   */
  get value() {

    return this.form.value;
  }

  isArrayOfStringa(value: any): boolean {
    return !Array.isArray(value) && !!value.length && value.every(item => typeof item === "string")
  }

  isObjectOfString(obj) {
    return (Object.prototype.toString.call(obj) === '[object String]');
  }


}
