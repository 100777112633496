import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(data: any, key?: any): any {
    if (!(data && key)) return;
    var result = {};
    for (var i = 0; i < data.length; i++) {
        if (!result[data[i][key]])
            result[data[i][key]] = [];
        result[data[i][key]].push(data[i])
    }
    return result;
}

}
