import { environment } from '../../../environments/environment';

export class ApiContants {

    public static readonly ApiEndPoint: string = environment.apiUrl;

    public static readonly login: string = ApiContants.ApiEndPoint + '/login';
    public static readonly actions: string = ApiContants.ApiEndPoint + + '/getActionsByRole';
    public static readonly standards: string = ApiContants.ApiEndPoint + + '/getStandards';

    public static readonly students: string = ApiContants.ApiEndPoint + + '/getStudents';
    public static readonly createStudent: string = ApiContants.ApiEndPoint + + '/createStudent';
    public static readonly updateStudent: string = ApiContants.ApiEndPoint + + '/updateStudent';
    public static readonly deleteStudents: string = ApiContants.ApiEndPoint + + '/deleteStudents';    
    public static readonly studentById: string = ApiContants.ApiEndPoint + + '/getStudentById';




}