export const environment = {
  production: true,

  // URL of production API
  apiUrl: 'https://a2stats.itraxpro.com/api/v1.0',

  // URL of Test API
  // apiUrl: 'https://itraxpro.com/api/v1.0',

  // browser storage maintain either 'localStorage' or 'sessionStorage'
  browserStorage: 'sessionStorage'
};
