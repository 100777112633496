import {
  ComponentFactoryResolver, EventEmitter, ComponentRef, OnChanges, Directive, Input, OnInit,
  ViewContainerRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { FieldConfig } from "../interface/field.interface";
import { InputComponent } from "../input/input.component";
import { RadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { DateComponent } from "../date/date.component";
import { CheckboxGroupComponent } from "../checkbox-group/checkbox-group.component";
import { SelectComponent } from "../select/select.component";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { TextareaComponent } from "../textarea/textarea.component";
import { ListComponent } from "../list/list.component";
import { FileComponent } from "../file/file.component";
import { NoteComponent } from "../note/note.component";
//   import { SubgroupComponent } from "../subgroup/subgroup.component";
// import { ButtonComponent } from "../button/button.component";

@Directive({
  selector: '[dynamicField]'

})
export class DynamicFieldDirective implements OnInit {

  /** only particular field data */
  @Input() field: FieldConfig;
  /** only particular field group data */
  @Input() group: FormGroup;

  @Input() onChange: Function;
  /** Total dynamic form "this" data */
  @Input() curRef: any;

  /** total form data values */
  @Input() formData: object;
  /** only particular field value */
  @Input() fieldData: any;
  @Input() onClick: Function;
  /**Dependent DropDown Function */
  // @Input() dependentDropDownOnChange: Function;

  /**In Dynamic form tag applied callback function */
  @Input() onCallBackFn: Function;
  /** Dynamic form sector using componment this value */
  @Input() curParentRef: any;


  componentRef: any;

  componentMapper = {
    input: InputComponent,
    radiobutton: RadiobuttonComponent,
    date: DateComponent,
    checkboxgroup: CheckboxGroupComponent,
    select: SelectComponent,
    checkbox: CheckboxComponent,
    textarea: TextareaComponent,
    list: ListComponent,
    file: FileComponent,
    note: NoteComponent,
    //   subgroup: SubgroupComponent,
    /* 
    button: ButtonComponent,
     */
  };

  /**
   * 
   * @param resolver 
   * @param container 
   */
  constructor(private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef) {
  }

  ngOnInit() {

    if (this.componentMapper.hasOwnProperty(this.field.type)) {
      const factory = this.resolver.resolveComponentFactory(this.componentMapper[this.field.type]);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
      this.componentRef.instance.onChange = this.onChange;
      this.componentRef.instance.dynamicFormCompRef = this.curRef;
      this.componentRef.instance.formData = this.formData;
      this.componentRef.instance.fieldData = this.fieldData;
      this.componentRef.instance.onClick = this.onClick;
      /**Dependent DropDown Function */
      // this.componentRef.instance.dependentDropDownOnChange = this.dependentDropDownOnChange;
      /**In Dynamic form tag applied callback function */
      this.componentRef.instance.onCallBackFn = this.onCallBackFn;
      /** Dynamic form sector using componment this value */
      this.componentRef.instance.curParentRef = this.curParentRef;
    } else {
      console.log(this.field.type + " component is not recognize, Please check component type once.");
    }

  }
}


import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {
  }

}