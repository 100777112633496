import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective {

  constructor() { }

  @Input('appTheme') message = 'Are you sure?';
  @Input() functionToRun = () => { };

  @HostListener('click')
  confirm() {
    if (window.confirm(this.message)) {
      this.functionToRun();
    }
  }

}
