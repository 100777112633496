import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Dashboard, Widget, WidgetOptions as DashboardOptions, UserDashboard as User } from "../_models";
import { GridType, CompactType } from "angular-gridster2";

import { ChartComponent } from "../dashboard/chart/chart.component";
import { TableComponent } from "../dashboard/table/table.component";

interface IDashboardService {
    getUserDashBoards(user: User): Array<Dashboard>;
    saveUserDashBoards(user: User): void;
    getDashBoardOptions(): DashboardOptions;
}
@Injectable()
export class DashboardService implements IDashboardService {

    private userDashboards: Map<number, Array<Dashboard>> = new Map<number, Array<Dashboard>>();
    private defaultUser: User;

    private dashBoards: Array<Dashboard> = new Array<Dashboard>();

    public widgetTypesArr = [
        { label: 'Chart', type: 'Chart' },
        { label: 'Table', type: 'Table' },
    ];

    constructor() {
        this.loadDashBoards();
    }

    private loadDashBoards(): void {
        this.defaultUser = new User();
        this.defaultUser.Id = 123;

        if (localStorage.getItem(this.defaultUser.Id.toString())) {
            const savdDashboards = localStorage.getItem(this.defaultUser.Id.toString());
            const dashboards = JSON.parse(savdDashboards) as Array<Dashboard>;
            dashboards.forEach((dashboard: Dashboard) => {
                dashboard.Widgets.forEach((widget: Widget) => {
                    if (widget.ComponentName === "Chart") {
                        widget.ComponentType = ChartComponent;
                    }
                    if (widget.ComponentName === "Table") {
                        widget.ComponentType = TableComponent;
                    }
                });
            });
            this.userDashboards.set(this.defaultUser.Id, dashboards);
        } else {

            this.dashBoards.push({
                Id: 1, Name: "dashboard-1", User: this.defaultUser, Widgets: [
                    {
                        Id: 1,
                        Name: "Nomination List",
                        ComponentName: "Table",
                        ComponentType: TableComponent,
                        x: 0, y: 0, cols: 1, rows: 1
                    },
                    {
                        Id: 2,
                        Name: "Chart",
                        ComponentName: "Chart",
                        ComponentType: ChartComponent,
                        x: 0, y: 0, cols: 1, rows: 1
                    }
                ]
            });
            this.userDashboards.set(this.defaultUser.Id, this.dashBoards);
        }
    }

    public addDashboards(id: any, name: any) {
        let newDashboardObj = {
            // Id: id.toString(),
            Id: id,
            Name: name,
            User: this.defaultUser,
            Widgets: []
        };
        this.dashBoards.push(newDashboardObj);
        this.userDashboards.set(this.defaultUser.Id, this.dashBoards);
        return this.dashBoards;
    }

    public getDashBoards(): Array<Dashboard> {
        // console.log(this.dashBoards, '-----------------------');
        return this.dashBoards;
    }

    public getUserDashBoards(user: User): Array<Dashboard> {
        return this.userDashboards.get(user.Id);
    }

    public saveUserDashBoards(user: User): void {
        localStorage.setItem(user.Id.toString(), JSON.stringify(this.userDashboards.get(user.Id)));
    }

    public getDashBoardOptions(): DashboardOptions {
        return {
            gridType: GridType.Fit,
            compactType: CompactType.None,
            margin: 10,
            outerMargin: true,
            outerMarginTop: null,
            outerMarginRight: null,
            outerMarginBottom: null,
            outerMarginLeft: null,
            mobileBreakpoint: 640,
            minCols: 1,
            maxCols: 100,
            minRows: 1,
            maxRows: 100,
            maxItemCols: 100,
            minItemCols: 1,
            maxItemRows: 100,
            minItemRows: 1,
            maxItemArea: 1000,
            minItemArea: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedColWidth: 105,
            fixedRowHeight: 105,
            keepFixedHeightInMobile: false,
            keepFixedWidthInMobile: false,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            ignoreMarginInRow: false,
            draggable: {
                delayStart: 0,
                enabled: true,
                ignoreContentClass: "gridster-item-content",
                ignoreContent: true,
                dragHandleClass: "drag-handler",
                dropOverItems: false
            },
            resizable: {
                enabled: true,
            },
            swap: true,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            // displayGrid: function () { },
            displayGrid: "none",
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: false,
            itemChangeCallback: function () { },
            itemResizeCallback: function () { }
        };
    }

    /******************************************************************* */

    public addWidgets(widgetsArray, newWidgetObj) {

        var ComponentType: any;
        if (newWidgetObj.widgetType == "Chart") {
            ComponentType = ChartComponent;
        } else if (newWidgetObj.widgetType == "Table") {
            ComponentType = TableComponent;
        }

        widgetsArray.push({
            Id: newWidgetObj.id,
            Name: newWidgetObj.name,
            ComponentName: newWidgetObj.widgetType,
            ComponentType: ComponentType,
            x: 0, y: 0, cols: 1, rows: 1
        });
        console.log("AddChartWidget---");
        console.log(widgetsArray);
        return widgetsArray;
    }


}

