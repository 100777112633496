import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {


  public chartType: string = 'StackedColumnChart';
  public chartImageTag: any = 'StackedColumnChart' + "-Image";
  public chartWidth: number = 100; //percentage value
  public chartHeight: number = 500; // px value
  public chartConfig: any[] = [];
  public chartSeriesConfig: any = '';
  public chartTypeDropdownList: any[] = [
    { type: "StackedColumnChart", name: "100% Stacked Column Chart" },
    { type: "StackedColumnChart3D", name: "100% 3D Stacked Column Chart" }
  ];;

  constructor() { }

  ngOnInit() {
  }

}
