import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { SessionService } from './session.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  /** API - GET SERVICE REQUEST */
  // API: GET 
  public get = (route: string) => {
    return this.http.get<any[]>(this.createCompleteRoute(route, API_URL));
  }

  /** API - POST SERVICE REQUEST */
  // API: POST 
  public post = (route: string, body: any) => {
    return this.http.post<any>(this.createCompleteRoute(route, API_URL), body);
  }

  /** API - PUT SERVICE REQUEST */
  // API: PUT /method/:id
  public put = (route: string, body: any) => {
    return this.http.put(this.createCompleteRoute(route, API_URL), body);
  }

  /** API - DELETE SERVICE REQUEST */
  // DELETE /method/:id
  public delete = (route: string) => {
    return this.http.delete<any>(this.createCompleteRoute(route, API_URL));
  }

  /** API - endPoint Setting Here */
  private createCompleteRoute = (route: string, envAddress: string) => {
    let endPoint = `${envAddress}/${route}`;
    return endPoint;
  }

  /** API - Headers Setting Here */
  private generateHeaders = () => {
    let sesData = this.sessionService.getData();
    let token = (sesData == null || sesData.Token == null) ? "" : sesData.Token;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', "token": token }) }
    return httpOptions;
  }

  /********************************************************************************************
  *  API - POST SERVICE REQUEST - Callback Method
  *  @param apiMethod  - API Method Name
  *  @param apiBody    - API Body Data
  *  @param callback   - callback method
  * ******************************************************************************************/
  postApiCallback = function (apiMethod: string, apiBody: any, callback: any) {
    /** API - POST REQUSET DATA */
    let requestData: any = apiBody;
    /*******************************************************************************************
     * API - POST SERVICE REQUEST 
     * ****************************************************************************************/
    this.post(apiMethod, requestData).subscribe((responseData) => {
      //Success callback
      // console.log(responseData);
      /** callback metthod */
      if (callback) {
        callback(responseData);
      }
    }, (error) => {
      //Error callback
      // console.log(error, error.statusText);
    });
  }

  /********************************************************************************************
  *  API - GET SERVICE REQUEST - Callback Method
  *  @param apiMethod  - API Method Name
  *  @param callback   - callback method
  * ******************************************************************************************/
  getApiCallback = function (apiMethod: string, callback: any) {
    /*******************************************************************************************
     * API - GET SERVICE REQUEST 
     * ****************************************************************************************/
    this.get(apiMethod).subscribe((responseData) => {
      //Success callback
      // console.log(responseData);
      /** callback metthod */
      if (callback) {
        callback(responseData);
      }
    }, (error) => {
      //Error callback
      // console.log(error, error.statusText);
    });
  }




}
