import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';

const BROWSER_STORAGE = environment.browserStorage;

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    ItemStorage: any = 'data.itemStorage';
    itemData: any = {
        Token: "",
        OrgName: "",
        OrgType: "",
        LoggedInUser: {},
        UserRoles:{}
    };

    constructor() {
        // console.log('----BROWSER_STORAGE--', BROWSER_STORAGE);
    }

    /**
     *  @param  object
     */
    simpleStringify = function (object) {
        var simpleObject = {};
        for (var prop in object) {
            if (!object.hasOwnProperty(prop)) {
                continue;
            }
            /*if (typeof (object[prop]) == 'object') {
                continue;
            }*/
            if (typeof (object[prop]) == 'function') {
                continue;
            }
            simpleObject[prop] = object[prop];
        }
        return JSON.stringify(simpleObject); // returns cleaned up JSON
    };

    /**
     * addData - set Local storage in browser
     *  @param  key
     *  @param  val
     */
    addData = function (key, val) {

        // console.log('----BROWSER_STORAGE--', BROWSER_STORAGE);
        /** check here browser storage condition */
        var data: any = (BROWSER_STORAGE === "localStorage") ? localStorage.getItem(this.ItemStorage) : sessionStorage.getItem(this.ItemStorage);

        if (data) {
            data = JSON.parse(data);
        } else {
            data = this.itemData;
        }
        data[key] = val;
        /** check here browser storage condition */
        (BROWSER_STORAGE === "localStorage") ? localStorage.setItem(this.ItemStorage, this.simpleStringify(data)) : sessionStorage.setItem(this.ItemStorage, this.simpleStringify(data));

    };

    /**
     * 
     * getData - get Local storage from browser
     */
    getData = function () {
        /** check here browser storage condition */
        var data: any = (BROWSER_STORAGE === "localStorage") ? localStorage.getItem(this.ItemStorage) : sessionStorage.getItem(this.ItemStorage);
        if (data) {
            data = JSON.parse(data);
        }
        return data || [];
    };

    /**
     * clear - remove Local storage from browser
     */
    clear = function () {
        this.itemData = {
            Token: "",
            OrgName: "",
            OrgType: "",
            LoggedInUser: {},
            UserRoles: {}
        };
        /** check here browser storage condition */
        (BROWSER_STORAGE == "localStorage") ? localStorage.setItem(this.ItemStorage, JSON.stringify(this.itemData)) : sessionStorage.setItem(this.ItemStorage, JSON.stringify(this.itemData));
    }

}