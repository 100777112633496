import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ValueCheckInArray'
})
export class ValueCheckInArrayPipe implements PipeTransform {

  transform(arr: string[], searchValue: string) {

    if (!searchValue) return arr;

    console.log(typeof arr, arr);

    let checkStatus: boolean = false;
    let j: any;
    for (j in arr) {
      if (arr[j] === searchValue) {
        checkStatus = true;
      } else {
        checkStatus = false;
      }
    }

    console.log(checkStatus);
    return checkStatus;

    if (!searchValue) return arr;

    return [12, 5, 8, 130, 44].indexOf(8);

  }

}
