import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, SessionService, CommonService, ApiService } from '../_services';
import { User, Role } from "../_models";
/** material realted Loading here */
import { MatSnackBar } from '@angular/material';
/** Snackbar Loading here */
import { MessageSnackbarComponent } from '../shared/snackbars/message-snackbar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: User;
  role: Role[] = [];

  title = 'Asquare Login';
  username: string;
  password: string;
  hide = true;
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errorMsg: string;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;


  /** dialog Config Variables */
  public durationInSeconds = 3;
  loginUserRole;
  /**
   * 
   * @param formBuilder 
   * @param router 
   * @param route 
   * @param authenticationService 
   * @param changeDetectorRef 
   * @param media 
   */
  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private snackBar: MatSnackBar, private sessionService: SessionService, private commonService: CommonService, private apiService: ApiService) {
    /**Breadcrumbs Page title */
    this.commonService.PAGE_TITLE = "";

    /** Responsive Code here */
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    // console.log(this.mobileQuery.matches);

    let sesData = this.sessionService.getData();
    this.loginUserRole = sesData.UserRoles;

    let token = (sesData == null || sesData.Token == null) ? "" : sesData.Token;
    if (token) {
      /*** Get Logged user data and setting classes  */
      var self = this;
      self.apiService.getApiCallback("getLoggedInUser", function (responseData: any) {
        //console.log('---call back-----', responseData);
        if (responseData) {
          self.router.navigate(['/layout']);
          // if (!self.loginUserRole.hasOwnProperty('Helper')) {
          //   self.router.navigate(['/layout/students']);
          // } else {
          //   self.router.navigate(['/layout/configurationreports']);
          // }
        }
        // self.router.navigate(['/layout/students']);
      });
      /** End */
      // redirect url
      self.router.navigate(['/layout']);

      // if (!self.loginUserRole.hasOwnProperty('Helper')) {
      //   self.router.navigate(['/layout/students']);
      // } else {
      //   self.router.navigate(['/layout/configurationreports']);
      // }

    }

  }

  /******************************************************************************************* 
   * SnackBar code
   * @param message 
   */
  public openSnackBar(message) {
    this.snackBar.openFromComponent(MessageSnackbarComponent, {
      data: message,
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }


  /**
   * ngOnInit
   */
  ngOnInit() {
    /** Form Create here */
    this.createForm();
    /** get return url from route parameters or default to '/' */
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/layout/students';
    // console.log(this.returnUrl);

  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() { return this.form.controls; }

  /** Form Fields set here */
  createForm() {
    /** Form control create here */
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]]
    });
  }

  /**
   * OnSubmitt function 
   * */
  onSubmit() {
    this.submitted = true;
    /** stop here if form is invalid */
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    /** dologin method here if form is valid */
    this.doLogin(this.f.username.value, this.f.password.value);
  }

  /**
   * @param username
   * @param password
   */
  private doLogin(username, password) {
    /** API - POST REQUSET DATA */
    let requestData: object = {
      username: username,
      password: password,
    };
    var self = this;
    this.authenticationService.login(requestData).subscribe((responseData) => {
      // console.log(responseData);
      if (responseData != null) {

        /** Set variable here */
        this.user = responseData;
        this.role = responseData.Roles;
        let roleObj = this.getUseRoles(this.role);

        // console.log(roleObj);

        /** Login User Token save in browser storage  */
        self.sessionService.addData("Token", responseData.Token);
        self.sessionService.addData("UserRoles", roleObj);

        /** Get Logged In User Data API */
        self.getLoggedInUser();

        /** Login User data save in local storage in browser */
        // localStorage.setItem('currentUser', JSON.stringify(this.user));
        // localStorage.setItem('currentUserRole', JSON.stringify(roleObj));
        self.router.navigate(['/layout']);

        // if (roleObj.hasOwnProperty('Branch_Admin') || roleObj.hasOwnProperty('Teacher')) {
        //   self.router.navigate(['/layout/students']);
        // } else if (roleObj.hasOwnProperty('Helper')) {
        //   self.router.navigate(['/layout/configurationreports']);
        // } else {
        //   self.router.navigate(['/layout']);
        // }

      } else {
      }
    }, (error) => {
      // console.log(error, error.statusText);
      if (error.status === 401) {
        this.errorMsg = 'Invalid credentials';
      } else {
        this.errorMsg = 'Sorry, Try again';
      }
      this.openSnackBar(this.errorMsg)
    });

  }


  /**
   * @param username
   * @param password
   */
  private getLoggedInUser() {
    /** API - Get REQUSET DATA */
    var self = this;
    this.authenticationService.getLoggedInUser().subscribe((responseData) => {
      // console.log(responseData);
      if (responseData != null) {
        self.commonService.setloggedInUser(responseData);
        /** Set data in browser Storage here */
        //self.sessionService.addData("LoggedInUser", responseData);
      } else {
        self.errorMsg = 'Response data is null';
      }
    }, (error) => {
      // console.log(error.error);
      let msg = (error.error.Message) ? error.error.Message : error.error.message;
      self.commonService.ToastMsg(msg);
    });

  }

  getUseRoles(roleArr) {
    let roleObj = {};
    roleArr.forEach(function (element) {
      roleObj[element.Name] = element.Id;
    });
    // console.log(roleObj);
    return roleObj;
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}

