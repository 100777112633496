import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  public allFormsData: any;
  // public  divPartitionColumns: number
  /**
   * Tabs name set in array
   */
  // public tabsArray: string[] = ['personal', 'parents', 'health'];
  public tabsArray: string[] = ['student', 'parents', 'guardian', 'declaration', 'document', 'admission'];

  public tabsArray_old: string[] = ['student', 'parents', 'guardian'];
  public tabsArraylength = this.tabsArray.length;

  /**
   * New array set here for output data 'personal': '',
   */
  private newOutPutObj = { 'student': '', 'parents': '', 'guardian': '', 'declaration': '', 'document': '', 'admission': '' };

  /**
   * New array set here for input data      'personal': '',
   */
  private newInPutObj = { 'student': '', 'parents': '', 'guardian': '', 'declaration': '', 'document': '', 'admission': '' };
  
  serviceproperty = 'Service Created';

  isDisplayOutPutJson = false;
  
  private currentUrl: string = "";
  private formValue: any;
  
  constructor() { }


  getDivPartitionColumns() {
    const divPartitionColumns = "col-md-"
    return divPartitionColumns;
  }

  getDivPartitionColumnsDefault() {
    const divPartitionColumnsDefault = "col-md-12"
    return divPartitionColumnsDefault;
  }

  
  setIndividualFormValue(data) {
    this.formValue = data;
  }

  getIndividualFormValue() {
    let temp1 = this.formValue;
    return temp1;
  }


  setUrl(url) {
    this.currentUrl = url;
  }

  getUrl() {
    let temp1 = this.currentUrl;
    return temp1;
  }

  /**
   * Set output data
   * @param outPutData 
   * @param navBtnData 
   */
  setOutPutData(outPutData, navBtnData) {

    let currentPath = navBtnData.current;

    for (let i = 0; i < this.tabsArraylength; i++) {
      if (this.tabsArray[i] == currentPath) {
        this.newOutPutObj[currentPath] = outPutData;
      }
    }

    // console.log("--------Set Output Object----------");
    // console.log(this.newOutPutObj);
    // console.log("-----------------------------------");
  }

  /**
   * get output data
   * @param navBtnData 
   */
  getOutputData(navBtnData) {
    // let redirectpath = navBtnData.next;
    // let currentPath = navBtnData.current;
    //let previousPath = navBtnData.previous;
    let temp = this.newOutPutObj;
    // this.clearData();
    return temp;
  }

  /**
   * set input data
   * @param inPutData 
   * @param navBtnData 
   */
  setInPutData(inPutData, navBtnData) {

    let currentPath = navBtnData.current;

    for (let i = 0; i < this.tabsArraylength; i++) {
      if (this.tabsArray[i] == currentPath) {
        this.newInPutObj[currentPath] = inPutData;
      }
    }

    // console.log("--------Set Input Object----------");
    // console.log(this.newInPutObj);
    // console.log("-----------------------------------");

  }

  /**
   * get input data
   * @param navBtnData 
   */
  getInputData(navBtnData) {
    let temp = this.newInPutObj;
    // this.clearData();
    return temp;
  }

  /**
   * clear data
   */
  clearData() {
    //this.outputData = undefined;
  }


}
