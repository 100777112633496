export class StudentAttendance {
    _id: string;
    BranchId: number;
    ClassId: number;
    IsActive: boolean;
    Month: number;
    OrganizationId: number;
    Section: string;
    StudentId: number;
    Year: number;
    ZoneId: number;
    Attendance: Attendance;
    RollNo: string;
    StudentName: string;
}

export class Attendance {
    Date: string;
    IsPresent: boolean;
    IsLeaveApplied: boolean;
    IsTransportBoarded: string;
    Time: boolean;
    Period: string;
}

export interface AttendanceReportFilter {
    classId: number;
    sectionID: string;

    /** Date Wise, Month Wise */
    reportType: string;

    /** Date Wise Parameters */
    fromDate: string;
    toDate: string;

    /** Month Wise Parameters */
    month: string;
}