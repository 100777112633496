export class VisitorEntries {
    _id: string;
    OrganizationId: number;
    BranchId: number;
    ZoneId: number;
    IsActive: boolean;
    Month: number;
    EndPointId: number;
    Year: number;
    Attendance: eVsistorEntry;
    RollNo: string;
    EmployeeName: string;
}

export class eVsistorEntry {
    Date: string;
  
    Time: boolean;
    Period: string;
}

export interface eVsistorEntryReportFilter {
    /** Date Wise, Month Wise */
    reportType: string;

    /** Date Wise Parameters */
    fromDate: string;
    toDate: string;

    /** Month Wise Parameters */
    month: string;
}