﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService, ApiService } from '../_services';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private sessionService: SessionService, private apiService: ApiService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let currentUser = this.sessionService.getData();
        let token = (currentUser == null || currentUser.Token == null) ? "" : currentUser.Token;
        if (token) {
            /*** Get Logged user data and setting classes  */
            var self = this;
            self.apiService.getApiCallback("getLoggedInUser", function (responseData: any) {
                // console.log('---call back-----', responseData);
                if (responseData) {
                    // authorised so return true
                    return true;
                } else {
                    // remove user from browser storage to log user out
                    this.sessionService.clear();
                    // not logged in so redirect to login page with the return url
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                }
            });
            /** End */

            // authorised so return true
            return true;
        }
        // remove user from browser storage to log user out
        this.sessionService.clear();
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }


}