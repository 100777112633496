export class Standard {
    _id:string;
    Id: number;
    Name: string;
    Sections: Section;
}

export class Section {
    id: number;
    Name: string;
} 