export class User {
    Id: number;
    Name: String;
    UserName: string;
    OrganizationId: number;
    ZoneId: number;
    BranchId: number;
    Roles: Role;
    Token: string;
    Sesid: string;
    Classes?: string;
    IsCorporate?: boolean;
    IsLogistics?: boolean;
    OrgType?: string;
    LoginType?: string;
    IsFirstLogin?: boolean;
}

export class Role {
    Id: number;
    Name: string;
}

export class UserDashboard {
    Id: number;
    Name: String;
    UserName: string;
}